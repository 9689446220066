export default [
  {
    title: 'Store Information',
    icon: 'mdi-cogs',
    to: 'SettingStoreInformation'
  },
  {
    title: 'Store Setting',
    icon: 'mdi-cogs',
    to: 'StoreSetting'
  },
  {
    title: 'VIP Campaign Setting',
    icon: 'mdi-tag',
    to: 'CampaignSetting'
  },
  {
    title: 'Payment Channel',
    icon: 'mdi-credit-card-outline',
    to: 'AvailablePaymentChannelSetting'
  },
  {
    title: 'Delivery Setting',
    icon: 'mdi-truck',
    to: 'DeliverySetting'
  },
  {
    title: 'Warehouse Setting',
    icon: 'mdi-warehouse',
    to: 'WarehouseSetting'
  },
  {
    title: 'Fulfillment Setting',
    icon: 'mdi-file-table-box-outline',
    to: 'OrderFulfillmentSetting'
  },
  {
    title: 'User Setting',
    icon: 'mdi-home-account',
    to: 'UserBranchSetting'
  },
  {
    title: 'CurrencyRates',
    icon: 'mdi-cash-multiple',
    to: 'CurrencyRatesSetting'
  },
  {
    title: 'Clear Cache',
    icon: 'mdi-cached',
    to: 'ClearCache'
  },
  {
    title: 'BC Logs',
    icon: 'mdi-microsoft-access',
    to: 'BCLogs'
  },
  {
    title: 'BC Queues',
    icon: 'mdi-human-queue',
    to: 'BCQueues'
  },
  {
    title: 'Develop Debug',
    icon: 'mdi-wrench',
    to: 'DevSetting'
  },
  {
    title: 'Release Note',
    icon: 'mdi-rocket',
    to: 'ReleaseNote'
  },
  {
    title: 'POS Management',
    icon: 'mdi-calculator',
    to: 'PosManagement'
  },
  {
    title: 'DHL Express Setting',
    icon: 'mdi-airport',
    to: 'DHLExpressSetting'
  },
  {
    title: 'ETax And VRT Setting',
    icon: 'mdi-cash-multiple',
    to: 'ETaxAndVRTSetting'
  }
]

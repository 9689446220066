var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"rounded-lg",attrs:{"max-width":"500px"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',[_c('span',{staticClass:"py-1",staticStyle:{"font-size":"1.75rem","font-weight":"500"}},[_vm._v(" Pick-up Warehouse Form ")])]),_c('v-card-text',[_c('validation-observer',{ref:"modal-form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"warehouse-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('WarehouseSelect',{attrs:{"items":_vm.filterWarehouses,"item-text":"name","item-value":"id","menu-props":{ offsetY: true },"rules":[
                  (v) => !!v || 'Warehouse is required'
                ],"label":"Warehouse*","outlined":"","return-object":""},on:{"select":function($event){return _vm.onSelectWarehouse($event)}},model:{value:(_vm.formData.warehouse),callback:function ($$v) {_vm.$set(_vm.formData, "warehouse", $$v)},expression:"formData.warehouse"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-autocomplete',{attrs:{"items":_vm.modes,"label":"Mode","item-text":"text","item-value":"value","menu-props":{ offsetY: true },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.mode),callback:function ($$v) {_vm.$set(_vm.formData, "mode", $$v)},expression:"formData.mode"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"Close Time","type":"time","color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.closeTime),callback:function ($$v) {_vm.$set(_vm.formData, "closeTime", $$v)},expression:"formData.closeTime"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"Pick-up Time","type":"time","color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.pickUpTime),callback:function ($$v) {_vm.$set(_vm.formData, "pickUpTime", $$v)},expression:"formData.pickUpTime"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"Location*","rules":{
                required: true,
                max: 80
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.location),callback:function ($$v) {_vm.$set(_vm.formData, "location", $$v)},expression:"formData.location"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"Postal Code*","rules":{
                required: true,
                max: 12
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.postalCode),callback:function ($$v) {_vm.$set(_vm.formData, "postalCode", $$v)},expression:"formData.postalCode"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"City Name*","rules":{
                required: true,
                max: 45
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.cityName),callback:function ($$v) {_vm.$set(_vm.formData, "cityName", $$v)},expression:"formData.cityName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"Country Code*","rules":{
                required: true,
                length: 2
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.countryCode),callback:function ($$v) {_vm.$set(_vm.formData, "countryCode", $$v)},expression:"formData.countryCode"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-textarea',{attrs:{"label":"Address Line 1*","rules":{
                required: true,
                max: 45
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.addressLine1),callback:function ($$v) {_vm.$set(_vm.formData, "addressLine1", $$v)},expression:"formData.addressLine1"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-textarea',{attrs:{"label":"Address Line 2","rules":{
                max: 45
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.addressLine2),callback:function ($$v) {_vm.$set(_vm.formData, "addressLine2", $$v)},expression:"formData.addressLine2"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-textarea',{attrs:{"label":"Address Line 3","rules":{
                max: 45
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.addressLine3),callback:function ($$v) {_vm.$set(_vm.formData, "addressLine3", $$v)},expression:"formData.addressLine3"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"Company Name*","rules":{
                required: true,
                max: 100
              },"color":"secondary","dense":"","outlined":"","hide-details":"","disabled":""},model:{value:(_vm.formData.companyName),callback:function ($$v) {_vm.$set(_vm.formData, "companyName", $$v)},expression:"formData.companyName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"Full Name*","rules":{
                required: true,
                max: 255
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.fullName),callback:function ($$v) {_vm.$set(_vm.formData, "fullName", $$v)},expression:"formData.fullName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"Phone*","rules":{
                required: true,
                max: 70
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gw-text-field',{attrs:{"label":"Email*","rules":{
                required: true,
                max: 70
              },"color":"secondary","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",staticStyle:{"gap":"0.5rem"},attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.loading},on:{"click":function($event){_vm.active = false}}},[_vm._v(" ปิด ")]),_c('v-btn',{attrs:{"type":"submit","color":"success","text":"","disabled":_vm.loading}},[_vm._v(" ยืนยัน ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
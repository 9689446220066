<template>
  <div>
    <div
      v-for="(material, index) in formData"
      :key="`material-${index}`"
      class="material-box elevation-3">
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-space-between">
          <p class="mt-1">
            {{ `Material #${index + 1}` }}
          </p>
          <div>
            <v-btn
              color="secondary"
              class="mr-2"
              small
              @click="selectMaterial(index)">
              <v-icon class="mr-1">
                mdi-palette-swatch
              </v-icon>
              เลือกอะไหล่
            </v-btn>
            <v-btn
              color="error"
              class="mr-2"
              small
              icon
              @click="deleteMaterial(index)">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6">
          <gw-drop-file
            v-model="material.imageUrls"
            :multiple="true"
            need-compress
            class="material-drop-file"
            s3-path="/sketch-images"
            caption="คลิกเพื่อเพิ่มรูปภาพ"
            :limit="10"
            label-bold />
        </v-col>
        <v-col
          cols="12"
          class="d-flex flex-wrap pa-0"
          md="6">
          <v-col
            md="6"
            sm="6"
            cols="12">
            <v-text-field
              v-model.trim="material.name"
              label="Name *"
              class="uppercase"
              hide-details
              outlined
              dense
              :rules="isFormValid(index)" />
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12">
            <v-combobox
              v-model.trim="material.factory"
              :items="factory"
              label="Factory *"
              :rules="isFormValid(index)"
              hide-details
              outlined
              deletable-chips
              small-chips
              dense
              class="uppercase" />
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12">
            <v-text-field
              v-model.trim="material.material"
              label="Material"
              class="uppercase"
              hide-details
              outlined
              dense />
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12">
            <v-text-field
              v-model.trim="material.size"
              label="Size"
              class="uppercase"
              hide-details
              outlined
              dense />
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12">
            <v-text-field
              v-model.trim="material.color"
              label="Color"
              class="uppercase"
              hide-details
              outlined
              dense />
          </v-col>
          <v-col
            v-if="!isSketch"
            md="6"
            sm="6"
            cols="12">
            <v-text-field
              v-model.number="material.amount"
              type="number"
              label="Amount"
              hide-details
              outlined
              :min="0"
              dense />
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12">
            <v-text-field
              v-model.number="material.unitPrice"
              type="number"
              label="Unit Price"
              hide-details
              outlined
              :min="0"
              dense />
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12">
            <v-select
              v-model="material.currency"
              :items="currencyOptions"
              :menu-props="{ offsetY: true }"
              label="Currency"
              item-text="label"
              item-value="value"
              outlined
              dense
              hide-details />
          </v-col>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model.trim="material.note"
            label="Note"
            dense
            hide-details
            outlined />
        </v-col>
      </v-row>
      <v-col
        cols="12"
        class="d-flex justify-space-between">
        <p class="mt-2">
          Patterns
        </p>
        <v-btn
          small
          color="secondary"
          @click="addMorePattern(index)">
          <v-icon class="mr-1">
            mdi-plus
          </v-icon>
          เพิ่ม Pattern
        </v-btn>
      </v-col>

      <v-row
        v-for="(pattern, indexPattern) in material.patterns"
        :key="`pattern-${indexPattern}`">
        <v-col
          cols="12"
          md="6">
          <gw-drop-file
            v-model="pattern.imageUrls"
            :multiple="true"
            need-compress
            class="material-drop-file"
            s3-path="/sketch-images"
            caption="คลิกเพื่อเพิ่มรูปภาพ"
            :limit="10"
            label-bold />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-textarea
            v-model.trim="pattern.note"
            label="Note"
            dense
            hide-details
            outlined />
          <v-btn
            v-if="material.patterns.length > 1"
            color="error"
            class="delete-material-btn mt-4 mr-2"
            @click="deletePattern(index, indexPattern)">
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-btn
      class="my-4"
      style="width: 100%;"
      color="primary"
      @click="addMoreMaterial()">
      <v-icon left>
        mdi-plus
      </v-icon>
      เพิ่มอะไหล่
    </v-btn>
    <material-list-modal
      v-model.trim="dialog"
      @on-select="onSelected($event)"
      @on-close="closeModal()" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MaterialsFactoryProvider from '@/resources/MaterialsFactoryProvider'
import MaterialListModal from './MaterialListModal.vue'

const MaterialsFactoryService = new MaterialsFactoryProvider()

export default {
  components: { MaterialListModal },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showAmount: {
      type: Boolean,
      default: false
    },
    isSketch: {
      type: Boolean,
      default: false
    },
    formValidates: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedIndex: -1,
      dialog: false,
      currencyOptions: [
        {
          label: 'บาท',
          value: 'thb'
        },
        {
          label: 'หยวน',
          value: 'cny'
        },
        {
          label: 'ดอลลาร์',
          value: 'usd'
        }
      ],
      factoryData: []
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    },
    factory: {
      get () {
        const results = this.factoryData.map((item) => item.name)
        return results
      },
      set (newVal) {
        const newValue = newVal[0] ? newVal[0].trim().toLowerCase() : ''
        this.formData.factory = newValue
      }
    }
  },
  created () {
    this.getMaterialFactory()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    selectMaterial (index) {
      this.selectedIndex = index
      this.dialog = true
    },
    onSelected (material) {
      const _material = JSON.parse(JSON.stringify(material))
      const payload = JSON.parse(JSON.stringify(this.formData))

      payload[this.selectedIndex] = {
        id: _material.id,
        sketchId: this.formData[this.selectedIndex]?.sketchId || '',
        materialId: _material?.materialId || '',
        imageUrls: _material.imageUrls,
        name: _material.name,
        factory: _material.factory,
        size: _material.size,
        color: _material.color,
        material: _material.material,
        collectionName: this.formData[this.selectedIndex]?.collectionName || '',
        unitPrice: _material.unitPrice,
        currency: _material.currency,
        amount: 0,
        note: this.formData[this.selectedIndex]?.note || '',
        patterns: this.formData[this.selectedIndex]?.patterns || _material.patterns || []
      }

      this.formData = [...payload]
      this.$emit('input', payload)

      this.closeModal()
    },
    closeModal () {
      this.selectedIndex = -1
      this.dialog = false
    },
    addMoreMaterial () {
      this.formData.push({
        materialId: '',
        imageUrls: [],
        name: '',
        factory: '',
        material: '',
        size: '',
        color: '',
        collectionName: '',
        unitPrice: 0,
        currency: 'thb',
        amount: 0,
        note: '',
        patterns: [{
          note: '',
          imageUrls: []
        }]
      })
    },
    addMorePattern (index) {
      const tmpData = [...this.formData]

      tmpData[index].patterns.push({
        imageUrls: [],
        note: ''
      })

      this.formData = tmpData
    },
    deletePattern (index, patternIndex) {
      const tmpData = [...this.formData]

      tmpData[index].patterns.splice(patternIndex, 1)

      this.formData = tmpData
    },
    deleteMaterial (index) {
      if (this.formData.length === 1) {
        this.formData.splice(index, 1, {
          materialId: '',
          imageUrls: [],
          name: '',
          factory: '',
          material: '',
          size: '',
          color: '',
          collectionName: '',
          unitPrice: 0,
          currency: 'thb',
          amount: 0,
          note: '',
          patterns: [{
            note: '',
            imageUrls: []
          }]
        })
      } else {
        this.formData.splice(index, 1)
      }
    },
    async getMaterialFactory () {
      try {
        const { data } = await MaterialsFactoryService.getAll({ limit: 9999 })
        this.factoryData = data.results
      } catch (error) {
        console.error('getMaterialFactory', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      }
    },
    isFormValid (index) {
      if (this.formValidates[index]) {
        return []
      }

      return [
        (v) => !!v || 'Field is require!'
      ]
    }
  }
}
</script>

<style>
.material-box {
  border: 1px solid #a3a3a3;
  padding: 16px;
  margin-top: 16px;
  border-radius: 6px;
}
.qty-field {
  width: 100%;
}

.material-drop-file .upload-file {
  height: 258px;
}

.delete-material-btn.v-btn {
  height: 40px !important;
}

.field-label {
  font-weight: bold;
}

.material-amount-input .v-messages__message {
  margin-top: 5px;
  font-size: 15px;
}
.uppercase input {
  text-transform: uppercase;
}
</style>

<template>
  <div
    class="d-flex flex-row px-4"
    style="gap: 16px;">
    <img
      :src="images.length ? images[0] : noPicture"
      style="width: 120px; height: auto; object-fit: cover;"
      class="rounded elevation-3 my-1" />
    <div
      class="d-flex flex-column"
      style="width: 100%;">
      <div class="d-flex flex-row justify-space-between align-center">
        <h4 class="product-name subtitle-1 font-weight-bold">
          {{ name }}
        </h4>
        <v-btn
          color="error"
          icon
          @click="removeItem()">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
      <p class="mb-0 text-body-2">
        <span class="text-bold">รหัสสินค้า :</span> {{ code }}
      </p>
      <p class="mb-0 text-body-2 text-uppercase">
        <span class="text-bold">ขนาด :</span> {{ size }}
      </p>
      <p class="mb-0 text-body-2 text-uppercase">
        <span class="text-bold">สี :</span> {{ color }}
      </p>
      <p
        v-if="isShowFit"
        class="mb-0 text-body-2 text-uppercase">
        <span class="text-bold">Fit : </span>
        <span
          v-if="fitting === 'FIT : -' || !fitting"
          class="red--text">ไม่ระบุ
        </span>
        <span v-else>{{ fitting }}</span>
      </p>
      <p class="mb-0 text-body-2">
        <span class="text-bold">คอลเลคชั่น :</span> {{ collection === 'COLLECTION : -' || !collection ? '-' : collection }}
      </p>
      <p class="mb-0 text-body-2">
        <span class="text-bold">ราคาขาย :</span> {{ price | showFullPriceFormat() }}
      </p>
      <div
        class="ml-auto"
        style="width: fit-content;">
        <v-text-field
          v-model.number="innerAmount"
          class="number-field"
          color="secondary"
          outlined
          dense
          min="1"
          hide-details
          prepend-inner-icon="mdi-minus"
          append-icon="mdi-plus"
          @click:prepend-inner="removeAmount()"
          @click:append="addAmount()" />
      </div>
    </div>
  </div>
</template>

<script>
import NoPicture from '@/assets/image/no_picture_available.png'

export default {
  props: {
    aliasId: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    fitting: {
      type: String,
      default: ''
    },
    collection: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      default: () => []
    },
    price: {
      type: Number,
      default: 0
    },
    amount: {
      type: Number,
      default: 1
    },
    isShowFit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      noPicture: NoPicture
    }
  },
  computed: {
    innerAmount: {
      get () {
        return this.amount
      },
      set (val) {
        this.$emit('update:amount', val || 1)
      }
    }
  },
  methods: {
    addAmount () {
      this.innerAmount += 1
    },
    removeAmount () {
      if (this.innerAmount === 1) {
        return
      }

      this.innerAmount -= 1
    },
    removeItem () {
      this.$emit('remove-item')
    }
  }
}
</script>

<style scoped>

</style>

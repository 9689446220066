<template>
  <section
    id="createOrder"
    class="d-flex justify-center">
    <v-form
      v-if="pageReady"
      ref="order-form"
      @submit.prevent="updateOrder()">
      <v-row>
        <v-col
          ref="orderTitle"
          cols="12"
          class="order-form-content-box">
          <h1 class="title">
            Create Order Form
          </h1>
        </v-col>

        <v-col
          cols="12"
          class="order-form-content-box">
          <h2 class="mb-2">
            Order Information
          </h2>
          <v-text-field
            v-model="form.productId"
            outlined
            label="Product ID"
            dense
            :disabled="!!sketch.sketchNo" />
          <v-text-field
            v-model="form.productFactoryId"
            outlined
            disabled
            label="Product Factory ID"
            dense />
          <v-select
            v-model="form.factory"
            :items="selectOptions.factories"
            item-text="name"
            return-object
            outlined
            label="Factory *"
            :rules="selectRules"
            dense />
          <v-select
            v-model="form.subCategory"
            :items="selectOptions.subCategories"
            label="Sub-Categories *"
            item-text="name"
            item-value="id"
            outlined
            :rules="selectRules"
            return-object
            dense />
          <date-with-menu
            v-model="form.orderDate"
            label="Order Date"
            :default-date="$dayjs().format('YYYY-MM-DD')"
            dense />
          <date-with-menu
            v-model="form.targetDate"
            label="Target Date"
            :default-date="$dayjs().add(1, 'month').format('YYYY-MM-DD')"
            dense />
        </v-col>
        <v-col
          cols="12"
          class="order-form-content-box">
          <h2>
            Fittings
          </h2>
          <v-row
            v-for="(fitting, index) in form.fitting"
            :key="`fitting-${index}`">
            <v-col
              cols="12"
              class="pb-0">
              <p class="mb-0">
                Fitting #{{ index + 1 }}
              </p>
            </v-col>
            <v-col
              cols="6"
              class="pt-0">
              <gw-drop-file
                v-model="fitting.images"
                :multiple="true"
                need-compress
                s3-path="/sketch-images"
                caption="คลิกเพื่อเพิ่มรูปภาพ"
                label-bold />
            </v-col>
            <v-col
              cols="6"
              class="mt-0">
              <v-textarea
                v-model="fitting.note"
                outlined
                label="Note"
                hide-details />
              <v-btn
                v-if="form.fitting.length > 1"
                color="error"
                class="delete-fitting-btn mt-4"
                @click="deleteFitting(index)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                style="width: 100%;"
                @click="addFitting()">
                <v-icon left>
                  mdi-plus
                </v-icon>
                เพิ่ม Fitting
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="order-form-content-box">
          <h2>
            Product Sku
          </h2>
          <v-combobox
            v-model="sizeDetail"
            label="Size *"
            class="mt-2"
            :items="sizes"
            multiple
            outlined
            deletable-chips
            small-chips
            dense
            :rules="arrayRules" />
          <v-combobox
            v-model="colorsSkus"
            label="Color *"
            :items="colors"
            multiple
            outlined
            deletable-chips
            small-chips
            dense
            :rules="arrayRules" />
        </v-col>
      </v-row>
      <v-row
        v-for="(item, i) in form.colors"
        :key="i">
        <v-col
          cols="12"
          class="order-form-content-box">
          <p
            class="field-label text-uppercase"
            style="font-size: 20px;">
            {{ item.color }} - Fabrics
          </p>
          <fabric-stage
            v-model="form.colors[i].fabrics"
            show-amount
            :form-validates="fabricFormValidates" />
        </v-col>
        <v-col
          cols="12"
          class="order-form-content-box">
          <p
            class="field-label text-uppercase"
            style="font-size: 20px;">
            {{ item.color }} - Materials
          </p>
          <material-stage
            v-model="form.colors[i].materials"
            show-amount
            :form-validates="materialFormValidates" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="order-form-content-box">
          <h2> Costing </h2>
          <manufac-calculate
            v-model="form"
            :margin="skuRatio"
            :is-thailand="isThailand" />
        </v-col>
        <v-col
          cols="12"
          class="order-form-content-box">
          <h2> SKU Production QTY </h2>
          <sku-detail
            ref="skuDetail"
            v-model="form" />
        </v-col>
        <v-col
          cols="12"
          class="order-form-content-box">
          <h2> Sizing </h2>
          <sizing-detail
            v-model="form.sizeDetail"
            class="mt-4"
            :size-unit.sync="form.sizeUnit" />
          <v-btn
            color="success"
            class="mt-4"
            style="width: 100%;"
            @click="updateOrder()">
            SAVE
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GetImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'
import FactoryProvider from '@/resources/FactoryProvider'
import PrototypeOrderProvider from '@/resources/PrototypeOrderProvider'
// import PrototypeProvider from '@/resources/PrototypeProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import PrototypeSubCategoryProvider from '@/resources/PrototypeSubCategoryProvider'
import PrototypeCurrency from '@/assets/json/PrototypeCurrency.json'
import MaterialStage from '../components/MaterialStage.vue'
import DateWithMenu from '../components/DateWithMenu.vue'
import ManufacCalculate from '../components/ManufactureCalculate.vue'
import SkuDetail from '../components/SkuDetail.vue'
import SizingDetail from '../components/SizingDetail.vue'
import FabricStage from '../components/FabricStage.vue'

const FactoryService = new FactoryProvider()
const PrototypeOrderService = new PrototypeOrderProvider()
// const PrototypeService = new PrototypeProvider()
const ProductAttributeService = new ProductAttributeProvider()
const PrototypeSubCategoryService = new PrototypeSubCategoryProvider()

export default {
  components: {
    MaterialStage,
    DateWithMenu,
    ManufacCalculate,
    SkuDetail,
    SizingDetail,
    FabricStage
  },
  data () {
    return {
      textBoxRules: [
        (v) => !!v || 'This field is required'
      ],
      selectRules: [
        (v) => (!!v && !!v.id) || 'This field is required'
      ],
      assistData: {
        productId: null,
        productFactoryId: null
      },
      pageReady: false,
      form: {
        fitting: [
          {
            images: [],
            note: ''
          }
        ],
        productId: null,
        productFactoryId: null,
        factory: {
          id: null,
          name: 'ไม่เลิือก'
        },
        orderDate: this.$dayjs().format('YYYY-MM-DD'),
        targetDate: this.$dayjs().add(1, 'month').format('YYYY-MM-DD'),
        manufacCountry: null,
        sellPrice: null,
        manufacCost: null,
        sizeDetail: [],
        sizeUnit: '',
        productSkus: [],
        collectionName: null,
        classification: null,
        merchandiser: null,
        designer: null,
        graphic: null,
        stylist: null,
        replacement: [],
        ratio: null,
        colors: [],
        subCategory: {
          id: null,
          name: '',
          parentId: null
        }
      },
      selectOptions: {
        factories: [],
        colors: [],
        sizes: [],
        subCategories: []
      },
      countries: PrototypeCurrency,
      sketch: {},
      prototypeState: '',
      arrayRules: [
        (v) => Boolean(v?.length || false) || 'Field is require!'
      ],
      materialFormValidates: [],
      fabricFormValidates: [],
      CNY_MULTIPLE: 5
    }
  },
  computed: {
    ...mapGetters({
      recentlyRoute: 'Route/recentlyRoute'
    }),
    factories () {
      return this.selectOptions.factories
    },
    colors () {
      return this.selectOptions.colors.map((s) => s.name)
    },
    sizes () {
      return this.selectOptions.sizes.map((s) => s.name)
    },
    priceWithoutVat () {
      return this.form?.sellPrice ? this.form.sellPrice / 1.07 : 0
    },
    skuRatio () {
      const ratio = Number(parseFloat(this.priceWithoutVat / this.totalCostPrice).toFixed(2)) || 0
      this.mapRatio(ratio)
      return ratio
    },
    isThailand () {
      return this.form.manufacCountry === 'thailand'
    },
    mappedCurrency () {
      return this.countries.reduce((acc, c) => ({ ...acc, [c.currency]: c.multiple }), {})
    },
    country () {
      return this.countries.find((c) => c.value === this.form.manufacCountry)
    },
    currencyMultiple () {
      return this.country?.multiple || 1
    },
    materialsPrice () {
      const allMaterials = this.form?.colors?.reduce((arr, c) => [...arr, ...c.materials], []) || []

      return allMaterials.reduce((acc, cur) => acc + (cur.amount * cur.unitPrice * this.mappedCurrency[cur.currency]), 0) || 0
    },
    fabricsPrice () {
      const allFabrics = this.form?.colors?.reduce((arr, c) => [...arr, ...c.fabrics], []) || []

      return allFabrics.reduce((acc, cur) => acc + (cur.amount * cur.unitPrice * this.mappedCurrency[cur.currency]), 0) || 0
    },
    totalCostPrice () {
      const cost = (this.form.manufacCost * this.currencyMultiple) + this.materialsPrice + this.fabricsPrice || 0

      this.mapCost(cost)

      return cost
    },
    prototypeId () {
      return this.$route?.params?.id || ''
    },
    sizeDetail: {
      get () {
        return this.form.sizeDetail.map((item) => item.size.trim())
      },
      set (newVal) {
        const newArr = [...new Set(newVal)].filter((s) => this.sizes.includes(s))
        this.setSizeDetail(newArr)
        this.setProductSkus(this.colorsSkus, newArr)
      }
    },
    colorsSkus: {
      get () {
        return this.form.colors.map((item) => item.color.trim())
      },
      set (newVal) {
        const newArr = [...new Set(newVal)].filter((c) => this.colors.includes(c))
        this.setProductSkus(newArr, this.sizeDetail)
        this.setColors(newArr)
      }
    }
  },
  watch: {
    'form.colors': {
      handler (newData) {
        this.materialFormValidates = newData.map((c) => c.materials.map((mat) => this.cleanMaterials([mat], false)))
        this.fabricFormValidates = newData.map((c) => c.fabrics.map((fab) => this.cleanFabrics([fab], false)))
      },
      deep: true
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setDarkMode: 'Style/setDarkMode',
      setIsWebpSupported: 'Style/setIsWebpSupported',
      initStore: 'Store/initStore'
    }),
    mapRatio (ratio = this.skuRatio) {
      this.form.productSkus.forEach((sku, index) => {
        this.form.productSkus[index].ratio = ratio
      })
    },
    mapCost (cost = this.totalCostPrice) {
      this.form.productSkus.forEach((sku, index) => {
        this.form.productSkus[index].cost = cost
      })
    },
    setColors (newColors) {
      const { productSkus, colors } = this.form
      const result = newColors.map((color) => {
        const colorData = this.selectOptions.colors.find((c) => c.name === color)
        const skus = productSkus.filter((sku) => sku.color === color)
        const oldData = colors.find((sku) => sku.color === color)
        const materials = oldData?.materials || this.sketch?.materials || [
          {
            materialId: '',
            imageUrls: [],
            name: '',
            factory: '',
            material: '',
            size: '',
            color: '',
            collectionName: '',
            unitPrice: 0,
            currency: 'thb',
            amount: 0,
            note: '',
            patterns: [{
              note: '',
              imageUrls: []
            }]
          }
        ]

        const fabrics = oldData?.fabrics || this.sketch?.fabrics || [
          {
            hangId: '',
            fabricNo: '',
            imageUrls: [],
            factory: null,
            size: '',
            color: '',
            note: '',
            collectionName: '',
            amount: 0,
            currency: 'thb',
            unitPrice: 0,
            patterns: [{
              note: '',
              imageUrls: []
            }]
          }
        ]

        return {
          id: colorData.id,
          color,
          totalQty: oldData?.totalQty || skus.reduce((sum, sku) => sum + Number(sku.quantity || 0), 0),
          materials: materials.map((material) => ({
            ...material,
            patterns: material?.patterns || [{
              note: '',
              imageUrls: []
            }]
          })),
          fabrics
        }
      })

      this.form.colors = JSON.parse(JSON.stringify(result))
    },
    setProductSkus (colors, sizes) {
      const { productSkus } = this.form
      const skusLength = productSkus.length
      const result = []
      colors.forEach((color) => {
        const colorData = this.selectOptions.colors.find((c) => c.name === color)

        sizes.forEach((size) => {
          const sizeData = this.selectOptions.sizes.find((s) => s.name === size)
          const oldData = productSkus.find((sku) => sku.color === color && sku.size === size)
          const data = {
            colorId: colorData.id,
            color,
            sizeId: sizeData.id,
            size,
            quantity: oldData?.quantity || null,
            ratio: this.skuRatio || 0,
            cost: this.totalCostPrice || 0,
            percent: oldData?.percent || Number((100 / sizes.length).toFixed(2)),
            productSellsuki: oldData?.productSellsuki || null
          }

          if (!(data?.productSellsuki?.productId)) {
            delete data.productSellsuki
          }

          result.push(data)
        })
      })
      this.form.productSkus.splice(0, skusLength, ...result)
    },
    setSizeDetail (newArr) {
      if (newArr.length >= this.form.sizeDetail.length) {
        newArr.forEach((size) => {
          const findIndex = this.form.sizeDetail.findIndex((item) => item.size === size)
          if (this.form.sizeDetail.length > 0 && findIndex !== -1) {
            this.form.sizeDetail.splice(findIndex, 1, {
              ...this.form.sizeDetail[findIndex]
            })
          } else if (this.form.sizeDetail.length > 0) {
            this.form.sizeDetail.push({
              options: this.form.sizeDetail[0].options.map((option) => ({
                key: option?.key || '',
                value: ''
              })),
              size
            })
          } else {
            this.form.sizeDetail.push({
              options: [{
                key: '',
                value: ''
              }],
              size
            })
          }
        })
      } else {
        this.form.sizeDetail.forEach((item, index) => {
          const findIndex = newArr.findIndex((size) => item.size === size)
          if (findIndex === -1) {
            this.form.sizeDetail.splice(index, 1)
          }
        })
      }
    },
    async updateOrder () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATE ORDER...'
        })

        if (!this.sketch.category) {
          this.setSnackbar({
            value: true,
            message: 'Please fill category / type in sketch before edit order.',
            type: 'error'
          })

          this.setLoading({ active: false })

          return
        }

        const valid = await this.$refs['order-form'].validate()
        if (!valid) {
          const el = this.$refs.orderTitle
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' })
          }

          return
        }
        const skus = this.form?.productSkus.reduce((acc, cur) => {
          const found = acc.find((item) => item.color === cur.color)
          if (found) {
            found.total += cur.percent
          } else {
            acc.push({ color: cur.color, total: cur.percent })
          }
          return acc
        }, [])

        const colorPercent = skus.map((sk) => {
          if (sk.total !== 100) {
            return sk.color
          }
          return ''
        }).filter((res) => res)

        if (valid && !colorPercent.length) {
          // const { data: existedProductId } = await PrototypeService.getPrototypeByProductId(this.form.productId)

          // if (existedProductId && this.assistData.productId !== this.form.productId) {
          //   const error = {
          //     code: 400,
          //     message: 'Duplicate Product Id.'
          //   }
          //   throw error
          // }

          // const { data: existedProductFactoryId } = await PrototypeService.getPrototypeByProductFactoryId(this.form.productFactoryId)

          // if (existedProductFactoryId && this.assistData.productFactoryId !== this.form.productFactoryId) {
          //   const error = {
          //     code: 400,
          //     message: 'Duplicate Product Factory Id.'
          //   }

          //   throw error
          // }

          const mappedForm = {
            ...this.form,
            collectionName: this.sketch.collectionName || this.form.collectionName || '',
            classification: this.sketch.classification || this.form.classification || ''
          }
          const productSkus = mappedForm.productSkus.map((sku) => {
            const colorSku = this.colorsSkus.find((c) => c === sku.color)
            const colorData = this.selectOptions.colors.find((c) => c.name === sku.color)
            const sizeData = this.selectOptions.sizes.find((s) => s.name === sku.size)

            return {
              colorId: colorData.id,
              color: colorData?.name || colorSku,
              sizeId: sizeData.id,
              size: sizeData.name || sku.size,
              quantity: sku?.quantity || null,
              ratio: this.skuRatio || 0,
              cost: this.totalCostPrice || 0,
              percent: sku?.percent || null,
              productSellsuki: colorSku?.productSellsuki || null
            }
          })

          mappedForm.productSkus = [...productSkus]

          const payload = this.removeUnuseData(mappedForm)

          await PrototypeOrderService.editOrder(this.prototypeId, this.setFormatOrder(payload))

          this.setSnackbar({
            value: true,
            message: 'Prototype has been updated',
            type: 'success'
          })

          this.redirectRoute()
        } else {
          this.$refs['skuDetail'].$el.scrollIntoView({ behavior: 'smooth' })
          if (colorPercent.length) {
            this.setSnackbar({
              value: true,
              message: `${colorPercent} is more or less 100 %`,
              type: 'error'
        })
          }
        }
      } catch (error) {
        console.error('updateOrder', error)
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    redirectRoute () {
      switch (this.prototypeState) {
        case 'order':
          if (this.recentlyRoute?.name === 'OrderFormList') {
            this.$router.push(this.recentlyRoute)
          } else {
            this.$router.push({ name: 'OrderFormList' })
          }
          break
        case 'manufacturing':
          if (this.recentlyRoute?.name === 'ManufactureList') {
            this.$router.push(this.recentlyRoute)
          } else {
            this.$router.push({ name: 'ManufactureList' })
          }
          break
        default:
          this.$router.push({ name: 'OrderFormList' })
          break
      }
    },
    async getOrder () {
      try {
        const { data } = await PrototypeOrderService.getOneOrderById(this.prototypeId)
        this.form = { ...this.form, ...data.order }
        this.form.colors = this.form.colors.map((color) => {
          const materials = color.materials.length
            ? color.materials
            : [
              {
                sketchId: '',
                materialId: '',
                imageUrls: [],
                name: '',
                factory: '',
                color: '',
                size: '',
                material: '',
                unitPrice: 0,
                amount: 0,
                currency: 'thb',
                collectionName: '',
                note: '',
                patterns: [{
                  note: '',
                  imageUrls: []
                }]
              }
            ]

          const fabrics = color.fabrics.length
            ? color.fabrics
            : [
              {
                sketchId: '',
                hangId: '',
                fabricId: '',
                imageUrls: [],
                factory: '',
                unitPrice: 0,
                currency: 'thb',
                amount: 0,
                note: '',
                patterns: [{
                  imageUrls: [],
                  note: ''
                }]
              }
            ]

          const colorData = this.selectOptions.colors.find((c) => c.name === color.color)

          return {
            ...color,
            id: colorData.id,
            materials,
            fabrics
          }
        })
        this.sketch = {
          ...data.sketch,
          materials: data.sketch.materials.length
            ? data.sketch.materials
            : [
              {
                sketchId: '',
                materialId: '',
                imageUrls: [],
                name: '',
                factory: '',
                color: '',
                size: '',
                material: '',
                unitPrice: 0,
                amount: 0,
                currency: 'thb',
                collectionName: '',
                note: '',
                patterns: [{
                  note: '',
                  imageUrls: []
                }]
              }
            ],
          fabrics: data.sketch.fabrics.length
            ? data.sketch.fabrics
            : [
              {
                sketchId: '',
                hangId: '',
                fabricId: '',
                imageUrls: [],
                factory: '',
                unitPrice: 0,
                currency: 'thb',
                amount: 0,
                note: '',
                patterns: [{
                  imageUrls: [],
                  note: ''
                }]
              }
            ]
        }
        this.prototypeState = data.state || 'order'

        this.assistData = {
          productId: this.form.productId,
          productFactoryId: this.form.productFactoryId
        }

        this.initDefaultObject()
      } catch (error) {
        console.error('getOrder', error)
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      }
    },
    async initSelectOptions () {
      try {
        const [factoryPromise, attributePromise, subCategoriesPromise] = await Promise.all([
          FactoryService.getAll(),
          ProductAttributeService.getAllProductAttribute(),
          PrototypeSubCategoryService.getAllMapped()
        ])

        this.selectOptions.factories = factoryPromise.data.results.sort((a, b) => a.id - b.id)
        this.selectOptions.colors = attributePromise.data.colors ? this.getColor(attributePromise.data.colors).sort((a, b) => a.id - b.id) : []
        this.selectOptions.sizes = attributePromise.data.size ? attributePromise.data.size.sort((a, b) => a.id - b.id) : []
        this.selectOptions.subCategories = subCategoriesPromise.data.map((d) => ({
          ...d,
          disabled: d.name === 'UNKNOWN'
        }))
      } catch (error) {
        console.error('initSelectOptions', error)
      }
    },
    getColor (arr, itemsDisabled) {
      return arr.map((color) => ({
        ...color,
        label: color.name,
        children: color.childs && color.childs.length > 0 ? this.getColor(color.childs, itemsDisabled) : undefined,
        gradientCss: GetImageOrGradientCss(color)
      }))
    },
    initDefaultObject () {
      const fitting = (this.form?.fitting && !Array.isArray(this.form.fitting)) ? [this.form.fitting] : [...this.form.fitting]
      this.form.fitting = fitting.length ? fitting : [{ images: [], note: '' }]
      this.form.materials = this.form?.materials?.length > 0 ? this.form.materials.map((material) => ({
        ...material,
        imageUrls: material?.imageUrls ? material.imageUrls : material.images,
        unitPrice: material.unitPrice ? material.unitPrice : 0,
        currency: material.currency ? material.currency : 'thb',
        amount: material.amount ? material.amount : 0,
        patterns: material?.patterns || [{
          note: '',
          imageUrls: []
        }]
      })) : [
        {
          sketchId: '',
          materialId: '',
          imageUrls: [],
          name: '',
          factory: '',
          color: '',
          size: '',
          material: '',
          unitPrice: 0,
          amount: 0,
          currency: 'thb',
          collectionName: '',
          note: '',
          patterns: [{
            note: '',
            imageUrls: []
          }]
        }
      ]
      this.form.fabrics = this.form?.fabrics?.length > 0 ? this.form.fabrics.map((fabric) => ({
        ...fabric,
        unitPrice: fabric.unitPrice ? fabric.unitPrice : 0,
        currency: fabric.currency ? fabric.currency : 'thb',
        amount: fabric.amount ? fabric.amount : 0
      })) : [
        {
          sketchId: '',
          hangId: '',
          fabricId: '',
          imageUrls: [],
          factory: '',
          unitPrice: 0,
          currency: 'thb',
          amount: 0,
          note: '',
          patterns: [{
            imageUrls: [],
            note: ''
          }]
        }
      ]
      this.form.factory = this.form?.factory || { name: null, id: null }
    },
    async initForm () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING ORDER...'
        })

        await this.initSelectOptions()
        await this.getOrder(this.prototypeId)

        this.pageReady = true
      } catch (error) {
        console.error(error)
      } finally {
        this.setLoading({ active: false })
      }
    },
    removeUnuseData (_obj) {
      const obj = _obj
      const keys = Object.keys(obj)

      if ('colors' in obj) {
        obj.colors = obj.colors.map((c) => {
          const tmpColor = { ...c }

          if ('materials' in tmpColor) {
            tmpColor.materials = this.cleanMaterials(tmpColor.materials)
          }

          if ('fabrics' in tmpColor) {
            tmpColor.fabrics = this.cleanFabrics(tmpColor.fabrics)
          }

          return tmpColor
        })
      }

      keys.forEach((key) => {
        if (key !== 'orderDate' && key !== 'targetDate' && key !== 'productSellsuki' && key !== 'options') {
          if (obj[key] && typeof obj[key] === 'object') {
            obj[key] = this.removeUnuseData(obj[key])
          } else if ((!obj[key] || JSON.stringify(obj[key]) === '{}')) {
            delete obj[key]
          }
        }
      })

      return JSON.stringify(obj) !== '{}' ? obj : null
    },
    cleanMaterials (materials, isClean = true) {
      const checkKeys = [
        {
          key: 'sketchId',
          type: 'string'
        },
        {
          key: 'materialId',
          type: 'string'
        },
        {
          key: 'imageUrls',
          type: 'array'
        },
        {
          key: 'name',
          type: 'string'
        },
        // {
        //   key: 'factory',
        //   type: 'string'
        // },
        {
          key: 'color',
          type: 'string'
        },
        {
          key: 'size',
          type: 'string'
        },
        {
          key: 'material',
          type: 'string'
        },
        {
          key: 'unitPrice',
          type: 'number'
        },
        {
          key: 'amount',
          type: 'number'
        },
        {
          key: 'currency',
          type: 'string',
          default: 'thb'
        },
        {
          key: 'collectionName',
          type: 'string'
        },
        // {
        //   key: 'patterns',
        //   type: 'array',
        //   default: [
        //     {
        //       note: '',
        //       imageUrls: []
        //     }
        //   ]
        // },
        {
          key: 'note',
          type: 'string'
        }
      ]

      if (isClean) {
        return materials
          .filter((mat) => !checkKeys.every((ck) => {
            switch (ck.type) {
              case 'string': return mat[ck.key] === '' || mat[ck.key] === ck?.default
              case 'number': return mat[ck.key] === 0 || mat[ck.key] === ck?.default
              case 'array': return mat[ck.key].length === 0 || JSON.stringify(mat[ck.key]) === JSON.stringify(ck?.default)
              default: return false
            }
          }))
          .map((mat) => ({
            ...mat,
            sketchId: this.sketch.sketchId || mat?.sketchId?.toUpperCase() || '',
            name: mat?.name?.toUpperCase() || '',
            factory: mat?.factory?.toUpperCase() || '',
            color: mat?.color?.toUpperCase() || '',
            size: mat?.size?.toUpperCase() || '',
            material: mat?.material?.toUpperCase() || '',
            collectionName: this.sketch.collectionName || mat?.collectionName?.toUpperCase() || ''
          }))
      }

      return materials.every((mat) => checkKeys.every((ck) => {
          switch (ck.type) {
            case 'string': return mat[ck.key] === '' || mat[ck.key] === ck?.default
            case 'number': return mat[ck.key] === 0 || mat[ck.key] === ck?.default
            case 'array': return mat[ck.key].length === 0 || JSON.stringify(mat[ck.key]) === JSON.stringify(ck?.default)
            default: return false
          }
        }))
    },
    cleanFabrics (fabrics, isClean = true) {
      const checkKeys = [
        {
          key: 'sketchId',
          type: 'string'
        },
        {
          key: 'hangId',
          type: 'string'
        },
        {
          key: 'fabricNo',
          type: 'string'
        },
        {
          key: 'imageUrls',
          type: 'array'
        },
        // {
        //   key: 'factory',
        //   type: 'string'
        // },
        {
          key: 'size',
          type: 'string'
        },
        {
          key: 'color',
          type: 'string'
        },
        {
          key: 'unitPrice',
          type: 'number'
        },
        {
          key: 'amount',
          type: 'number'
        },
        {
          key: 'currency',
          type: 'string',
          default: 'thb'
        },
        {
          key: 'collectionName',
          type: 'string'
        },
        // {
        //   key: 'patterns',
        //   type: 'array',
        //   default: [
        //     {
        //       note: '',
        //       imageUrls: []
        //     }
        //   ]
        // },
        {
          key: 'note',
          type: 'string'
        }
      ]

      if (isClean) {
        return fabrics
        .filter((fab) => !checkKeys.every((ck) => {
          switch (ck.type) {
            case 'string': return fab[ck.key] === '' || fab[ck.key] === ck?.default
            case 'number': return fab[ck.key] === 0 || fab[ck.key] === ck?.default
            case 'array': return fab[ck.key].length === 0 || JSON.stringify(fab[ck.key]) === JSON.stringify(ck?.default)
            default: return false
          }
        }))
        .map((fab) => ({
          ...fab,
          sketchId: this.sketch.sketchId || fab?.sketchId?.toUpperCase() || '',
          hangId: fab?.hangId?.toUpperCase() || '',
          fabricNo: fab?.fabricNo?.toUpperCase() || '',
          factory: fab?.factory?.toUpperCase() || '',
          size: fab?.size?.toUpperCase() || '',
          color: fab?.color?.toUpperCase() || '',
          collectionName: this.sketch.collectionName || fab?.collectionName?.toUpperCase() || ''
        }))
      }

      return fabrics.every((fab) => checkKeys.every((ck) => {
          switch (ck.type) {
            case 'string': return fab[ck.key] === '' || fab[ck.key] === ck?.default
            case 'number': return fab[ck.key] === 0 || fab[ck.key] === ck?.default
            case 'array': return fab[ck.key].length === 0 || JSON.stringify(fab[ck.key]) === JSON.stringify(ck?.default)
            default: return false
          }
        }))
    },
    addFitting () {
      this.form.fitting.push({
        images: [],
        note: ''
      })
    },
    deleteFitting (index) {
      this.form.fitting.splice(index, 1)
    },
    setFormatOrder (orders) {
      const colors = orders.colors.map((color) => {
        const materials = color.materials.filter((mat) => ((mat.id && mat.name) || (!mat.id && mat.name)))
        const fabrics = color.fabrics.filter((fab) => ((fab.id && fab.hangId && fab.fabricNo) || (!fab.id && fab.hangId && fab.fabricNo)))

        return { ...color, materials, fabrics }
      })

      return { ...orders, colors }
    }
  }
}
</script>

<style scoped>
.delete-fitting-btn.v-btn {
  height: 40px !important;
}
#createOrder {
  max-width: 100vw;
}
.order-form-content-box {
  padding: 15px;
  border-radius: 6px;
  margin-top: 16px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  background-color: #fff;
}
.create-order-form {
  margin: 16px 0px;
}
.field-label {
  font-weight: bold;
  margin: 0px;
}
.capitalize {
  text-transform: capitalize;
}
.dash-border {
  border: 2px dashed #555555;
  border-radius: 5px;
}
</style>

<template>
  <v-dialog
    v-model="modal"
    @click:outside="closeModal()">
    <v-card>
      <v-card-title>
        <span class="headline">{{ editMode ? 'แก้ไข' : 'เพิ่ม' }} Celeb</span>
      </v-card-title>
      <v-card-text v-show="!loading">
        <validation-observer
          ref="create-celeb-form"
          tag="form"
          @submit.prevent="submitForm()">
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="12">
              <v-row>
                <v-col cols="12">
                  <h3>ทั่วไป</h3>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    label="ชื่อ"
                    hide-details
                    outlined
                    dense />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.email"
                    label="อีเมล"
                    hide-details
                    outlined
                    dense />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.tel"
                    label="เบอร์โทรศัพท์"
                    :rules="requiredRule"
                    hide-details
                    outlined
                    dense />
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-space-between">
                  <h3>โซเชียล</h3>
                  <v-btn
                    x-small
                    color="primary"
                    @click="addMoreSocial()">
                    เพิ่มช่องทางโซเชียล
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-row
                    v-for="(social, index) in form.socialMedias"
                    :key="`social-media-${index}`">
                    <v-col cols="3">
                      <v-combobox
                        v-model="form.socialMedias[index].platform"
                        label="ช่องทาง"
                        :items="socialChannels"
                        item-text="text"
                        item-value="value"
                        :return-object="false"
                        outlined
                        hide-details
                        dense />
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-model="form.socialMedias[index].accountName"
                        label="แอคเคาท์"
                        hide-details
                        outlined
                        dense />
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        v-if="index !== 0"
                        icon
                        @click="deleteSocial(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              class="address-box"
              cols="12"
              md="6"
              sm="12">
              <v-row>
                <v-col cols="12">
                  <h3>ที่อยู่</h3>
                </v-col>

                <v-col cols="12">
                  <v-form
                    ref="address-form"
                    @submit.prevent="onValidate()">
                    <div class="shipping-address-box-edit">
                      <v-text-field
                        v-model.trim="form.address.firstName"
                        outlined
                        label="ชื่อ"
                        class="shipping-address-field"
                        dense />
                      <v-text-field
                        v-model.trim="form.address.lastName"
                        outlined
                        label="นามสกุล"
                        class="shipping-address-field"
                        dense />
                      <v-text-field
                        v-model.trim="form.address.contactNo"
                        outlined
                        label="เบอร์โทรศัพท์"
                        class="shipping-address-field"
                        dense />
                      <v-text-field
                        v-model.trim="form.address.email"
                        outlined
                        label="อีเมล"
                        class="shipping-address-field"
                        dense />
                      <v-text-field
                        v-model.trim="form.address.taxId"
                        outlined
                        label="Tax ID"
                        class="shipping-address-field"
                        dense />
                      <v-text-field
                        v-model.trim="form.address.address"
                        outlined
                        label="ที่อยู่"
                        class="shipping-address-field"
                        dense />
                      <v-text-field
                        v-model.trim="form.address.address2"
                        outlined
                        label="ที่อยู่ (ต่อ)"
                        class="shipping-address-field"
                        dense />
                      <v-autocomplete
                        v-model="form.address.country"
                        outlined
                        label="ประเทศ"
                        color="secondary"
                        :items="shippingCountries"
                        item-text="label"
                        item-value="country"
                        dense
                        class="shipping-address-field" />
                      <v-address-field
                        v-model.trim="form.address.subDistrict"
                        outlined
                        label="ตำบล"
                        type="sub-district"
                        class="shipping-address-field"
                        dense
                        @select="onSelectAddress($event)" />
                      <v-address-field
                        v-model.trim="form.address.district"
                        outlined
                        label="อำเภอ"
                        type="district"
                        class="shipping-address-field"
                        dense
                        @select="onSelectAddress($event)" />
                      <v-address-field
                        v-if="isThailand"
                        v-model.trim="form.address.stateProvince"
                        outlined
                        label="จังหวัด"
                        type="province"
                        class="shipping-address-field"
                        dense
                        @select="onSelectAddress($event)" />
                      <v-combobox
                        v-else
                        v-model="form.address.stateProvince"
                        outlined
                        :search-input.sync="searchProvince"
                        label="State Province"
                        color="secondary"
                        :items="locations"
                        item-text="city"
                        item-value="city"
                        :rules="[
                          (v) => !!v && !isError.province || 'Field is require.'
                        ]"
                        :loading="checking"
                        hide-details
                        class="shipping-address-field"
                        @input="onSelectLocation($event, true)">
                        <template #item="{ item }">
                          {{ `${item.city} ${item.postalCode || item.cityDistrict || ''}` }}
                        </template>
                      </v-combobox>
                      <v-address-field
                        v-if="isThailand"
                        v-model.trim="form.address.postcode"
                        outlined
                        label="รหัสไปรษณีย์"
                        type="postcode"
                        class="shipping-address-field"
                        dense
                        @select="onSelectAddress($event)" />
                      <v-combobox
                        v-else-if="isHavePostalCode"
                        v-model="form.address.postcode"
                        :search-input.sync="searchPostalCode"
                        label="Postcode"
                        color="secondary"
                        :items="locations"
                        item-text="postalCode"
                        item-value="postalCode"
                        :rules="[
                          (v) => !!v && !isError.postcode || 'Field is require.'
                        ]"
                        :loading="checking"
                        hide-details
                        class="shipping-address-field"
                        @input="onSelectLocation($event, false)">
                        <template #item="{ item }">
                          {{ `${item.postalCode} ${item.city}` }}
                        </template>
                      </v-combobox>
                    </div>
                  </v-form>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              class="text-center">
              <v-btn
                v-if="!editMode"
                color="error"
                class="mr-4"
                @click="clearForm()">
                ล้างค่า
              </v-btn>
              <v-btn
                v-if="editMode"
                color="error"
                class="mr-4"
                @click="closeModal()">
                ปิด
              </v-btn>
              <v-btn
                color="success"
                type="submit">
                บันทึก
              </v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-text
        v-show="loading"
        class="text-center">
        <v-progress-circular
          size="70"
          indeterminate
          color="primary"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VAddressField from '@/components/form/VAddressField.vue'
import { mapActions } from 'vuex'

import CelebProvider from '@/resources/CelebProvider'
import DHLProvider from '@/resources/DHLProvider'
import ShippingCountries from '@/assets/json/ShippingCountries.json'

const CelebService = new CelebProvider()
const DHLServices = new DHLProvider()

export default {
  components: {
  VAddressField
  },
  props: {
    value: {
      type: Boolean,
      require: true
    },
    editData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      editMode: false,
      loading: false,
      checking: false,
      shippingCountries: ShippingCountries,
      requiredRule: [
        (v) => !!v || 'Field is required!'
      ],
      socialChannels: [
        { text: 'FACEBOOK', value: 'facebook' },
        { text: 'INSTAGRAM', value: 'instagram' },
        { text: 'TIKTOK', value: 'tiktok' },
        { text: 'X / TWITTER', value: 'x/twitter' }
      ],
      form: {
        name: '',
        email: '',
        tel: '',
        socialMedias: [
          {
            platform: null,
            accountName: null
          }
        ],
        address: {
          contactNo: '',
          firstName: '',
          lastName: '',
          address: '',
          address2: '',
          district: '',
          subDistrict: '',
          stateProvince: '',
          country: 'Thailand',
          postcode: '',
          note: '',
          email: ''
        }
      },
      checkTimeout: null,
      locations: [],
      isError: {
        province: false,
        postcode: false
      },
      searchProvince: '',
      searchPostalCode: ''
    }
  },
  computed: {
    modal: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    isThailand () {
      return this.form.address.country?.toUpperCase() === 'THAILAND'
    },
    isHavePostalCode () {
      if (!this.locations.length) {
        return true
      }

      return this.locations.some((v) => v.postalCode)
    },
    postalCodePass () {
      return (this.isHavePostalCode && this.form.address.stateProvince && this.form.address.postcode)
        || (!this.isHavePostalCode && this.form.address.stateProvince)
    }
  },
  watch: {
    modal (val) {
      if (val) {
        this.searchProvince = this.editData?.address?.stateProvince || ''
        this.searchPostalCode = this.editData?.address?.postcode || ''
      }
    },
    editData (value) {
      if (value) {
        this.form = value
        this.editMode = true
      }

      if (!value) {
        this.form = {
          name: '',
          email: '',
          tel: '',
          socialMedias: [
            {
              platform: null,
              accountName: null
            }
          ],
          address: {
            contactNo: '',
            firstName: '',
            lastName: '',
            address: '',
            address2: '',
            district: '',
            subDistrict: '',
            stateProvince: '',
            country: 'Thailand',
            postcode: '',
            note: '',
            email: ''
          }
        }

        this.editMode = false
      }
    },
    searchProvince () {
      clearTimeout(this.checkTimeout)
      this.checkTimeout = setTimeout(() => this.addressCheck(), 500)
    },
    searchPostalCode () {
      clearTimeout(this.checkTimeout)
      this.checkTimeout = setTimeout(() => this.addressCheck(), 500)
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    addMoreSocial () {
      this.form.socialMedias.push({ platform: null, accountName: null })
    },
    deleteSocial (index) {
      this.form.socialMedias.splice(index, 1)
    },
    async onValidate () {
      await this.$refs['address-form'].validate()
    },
    onSelectLocation (val, isCity = false) {
      if (!val) {
        this.searchProvice = isCity ? '' : this.searchProvice
        this.searchPostalCode = isCity ? this.searchPostalCode : ''

        return
      }

      this.searchProvice = val.city
      this.searchPostalCode = val?.postalCode || ''
      this.form.address.stateProvince = val.city
      this.form.address.postcode = val?.postalCode || ''
    },
    async addressCheck () {
      this.isError = {
        province: false,
        postcode: false
      }

      try {
        this.checking = true

        const foundCountry = this.shippingCountries.find((c) => c.country.toLowerCase() === this.form?.address?.country?.toLowerCase())

        if (!foundCountry) {
          throw Error('Country or postal code was wrong.')
        }

        await this.onValidate()

        if (foundCountry.code.toUpperCase() === 'TH') {
          return
        }

        const { data } = await DHLServices.postalCodeCheck({
          countryCode: foundCountry.code,
          city: this.searchProvince || '',
          postalCode: this.searchPostalCode || ''
        })

        this.locations = data.locations

        if (!data.isMatch) {
          this.isError = {
            province: data.errorField === 'city',
            postcode: data.errorField === 'postalCode'
          }
        }

        await this.onValidate()
      } catch (error) {
        console.error('addressCheck', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.checking = false
      }
    },
    async createCeleb () {
      try {
        this.loading = true
        if (this.validateForm(this.form)) {
          const payload = this.removeNullSocialMedias(this.form)

          const { data } = await CelebService.createCeleb(payload)

          if (data) {
            this.setSnackbar({
              value: true,
              message: 'Celeb created',
              type: 'success'
            })

            this.clearForm()
            this.$emit('create:celeb')
          }

          if (!data) {
            this.setSnackbar({
              value: true,
              message: 'something went wrong, pls try again or contact dev team',
              type: 'error'
            })
          }
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[error on create celeb]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async editCeleb () {
      try {
        this.loading = true
        if (this.validateForm(this.form)) {
          const payload = this.removeNullSocialMedias(this.form)

          const { data } = await CelebService.editCeleb(this.editData.id, payload)

          if (data) {
            this.setSnackbar({
              value: true,
              message: 'Celeb edited',
              type: 'success'
            })

            this.$emit('edit:celeb')
          }

          if (!data) {
            this.setSnackbar({
              value: true,
              message: 'something went wrong, pls try again or contact dev team',
              type: 'error'
            })
          }
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[error on edit celeb]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async submitForm () {
      if (!this.editMode) {
        this.createCeleb()
      }

      if (this.editMode) {
        this.editCeleb()
      }
    },
    validateForm (form) {
      let valid = true
      const requireds = [
        'firstName',
        'lastName',
        'contactNo',
        'address',
        'subDistrict',
        'district',
        'stateProvince',
        'country'
      ]

      if (this.isHavePostalCode) {
        requireds.push('postcode')
      }

      for (const required of requireds) {
        if (!form.address[required]) {
          this.setSnackbar({
            value: true,
            message: `${required.toUpperCase()} is required`,
            type: 'error'
          })

          valid = false
          break
        }
      }

      if (!form.name && !form.email && !form.tel) {
        this.setSnackbar({
          value: true,
          message: 'Please enter some of (name, email, tel)',
          type: 'error'
        })

        valid = false
      }

      return valid
    },
    removeNullSocialMedias (form) {
      form.socialMedias.forEach((social, index) => {
        if (!social.platform || !social.accountName) {
          form.socialMedias.splice(index, 1)
        }
      })

      return form
    },
    clearForm () {
      this.form = {
        name: '',
        email: '',
        tel: '',
        socialMedias: [
          {
            platform: null,
            accountName: null
          }
        ],
        address: {
          contactNo: '',
          firstName: '',
          lastName: '',
          address: '',
          address2: '',
          district: '',
          subDistrict: '',
          stateProvince: '',
          country: 'Thailand',
          postcode: '',
          note: '',
          email: ''
        }
      }
    },
    onSelectAddress (address) {
      this.form.address.subDistrict = address?.subDistrict || ''
      this.form.address.district = address?.district || ''
      this.form.address.stateProvince = address?.province || ''
      this.form.address.postcode = address?.postcode || ''
    },
    closeModal () {
      this.modal = false
      this.$emit('clear:edit')
      this.clearForm()
    }
  }
}
</script>

<style  scoped>
/* .address-box {
  max-height: 400px;
} */

@media screen and (min-width: 768px) {
  .address-box {
    overflow-y: auto;
    border: 1px solid black;
    max-height: 400px;
  }
}

</style>

<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row>
      <v-col cols="4">
        <v-row
          class="elevation-3 rounded products-row"
          style="height: 100%;">
          <v-col cols="12">
            <side-bar
              :products="products"
              :fetching-product="gettingProducts"
              @add-product="addProductToSelected($event)"
              @search="getProducts($event)" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-row
          class="elevation-3 rounded products-row"
          style="height: 100%;">
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between align-center">
              <h2>RFID Generator</h2>
              <v-btn
                color="secondary"
                :loading="exporting"
                :disabled="!selectedProduct.length"
                @click="onExport()">
                EXPORT
              </v-btn>
            </div>
            <v-divider class="my-4" />
            <div
              class="d-flex flex-column"
              style="gap: 16px; overflow-y: auto; max-height: calc(100vh - 220px);">
              <ItemBox
                v-for="(p, i) in selectedProduct"
                :key="`selected-${i}`"
                v-bind="p"
                :amount.sync="p.amount"
                :is-show-fit="isShowFit"
                @remove-item="removeItem(i)" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
// import { generateQRCode } from '@/assets/js/helper'
import RFIDGenerator from '@/assets/js/RFIDGenerate'
import ConsumerTypes from '@/assets/js/ConsumerTypes'
import ProductProvider from '@/resources/ProductProvider'
import StoreProvider from '@/resources/StoreProvider'
import SideBar from '../components/sideBar.vue'
import ItemBox from '../components/itemBox.vue'

const StoreService = new StoreProvider()
const ProductService = new ProductProvider()

export default {
  components: {
    SideBar,
    ItemBox
  },
  data () {
    return {
      gettingProducts: false,
      exporting: false,
      selectedProduct: [],
      products: [],
      webUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      store: 'Store/store'
    }),
    isShowFit () {
      return this.store?.id === '645a1d00765d5519beeb97f6'
    }
  },
  async mounted () {
    await this.getProducts()
    await this.getWebUrl()
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    async getProducts (search = '') {
      if (this.gettingProducts) {
        return
      }

      try {
        this.gettingProducts = true

        const { data } = await ProductService.getProducts({ search })
        this.products = this.mappingSkus(data.results)
      } catch (error) {
        console.error('getting product', error)
        this.setErrorPage(error.code)
      } finally {
        this.gettingProducts = false
      }
    },
    mappingSkus (products) {
      const allSkus = []
      products.forEach((product) => {
        const tempSkus = product.skus.map((sku) => ({
          ...sku,
          name: product.name,
          model: product.model,
          collection: product.gwCollection,
          prototypeCategory: product.productPrototype?.category || 'UNKNOWN',
          tags: product.tags
        }))

        allSkus.push(...tempSkus)
      })

      return allSkus.map((sku) => ({
          aliasId: sku.aliasId,
          code: sku.code,
          name: sku.name,
          collection: sku.collection?.nickname ? sku.collection?.nickname : 'COLLECTION : -',
          prototypeCategory: sku.prototypeCategory,
          size: sku.size?.name || '',
          color: sku.color?.name || '',
          price: sku.price,
          images: sku.images,
          fitting: this.getFittingText(sku.tags)
        }))
    },
    getFittingText (tags) {
      if (this.isShowFit) {
        if (tags && tags.length) {
          const fittingTag = tags.find((tag) => ['UNISEX', 'WOMEN', 'KIDS', 'MEN'].includes(tag.toUpperCase()))
          if (fittingTag) {
            return fittingTag.toUpperCase()
          }
        }
        return 'FIT : -'
      }
      return ''
    },
    initOptions (size, color) {
      let option = ''

      if (size.name && color.name) {
        option = `${size.name.toUpperCase()} • ${color.name}`
      } else if (size.name && !color.name) {
        option = size.name.toUpperCase()
      } else if (!size.name && color.name) {
        option = color.name
      }

      return option
    },
    addAmount (index) {
      this.selectedProduct[index].amount++
    },
    removeAmount (index) {
      if (this.selectedProduct[index].amount > 1) {
        this.selectedProduct[index].amount--
      }
    },
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    },
    async getWebUrl () {
      const { data: { webUrl } } = await StoreService.getStoreSetting()
      this.webUrl = webUrl
    },
    async addProductToSelected (_product) {
      // const qr = await generateQRCode(`${(this.webUrl)}/${_product.code}`)
      if (this.isShowFit && _product.fitting === 'FIT : -') {
        this.setSnackbar({
          value: true,
          message: 'รายการสินค้านี้ไม่ได้ระบุ Fit กรุณาตรวจสอบ',
          type: 'error',
          timeout: 6000
        })
      }
      const url = `${(this.webUrl)}/${_product.code}`
      const product = {
        ..._product,
        url,
        consumerType: ConsumerTypes(_product.prototypeCategory.toLowerCase()),
        productionDate: this.$dayjs().subtract(2, 'month').format('MM-YYYY')
      }
      const duplicated = this.selectedProduct.findIndex((selected) => selected.code === product.code)
      if (duplicated === -1) {
        this.selectedProduct.push({ ...product, amount: 1 })
      } else {
        this.selectedProduct[duplicated].amount++
      }
    },
    removeItem (index) {
      this.selectedProduct.splice(index, 1)
    },
    async onExport () {
      try {
        this.exporting = true

        const mapped = this.selectedProduct.map((v) => ({
          aliasId: v.aliasId,
          amount: v.amount,
          code: v.code,
          collection: v.collection,
          color: v.color,
          size: v.size,
          url: v.url,
          consumerType: v.consumerType,
          productionDate: v.productionDate,
          price: `${v.price.toLocaleString()} THB`,
          fitting: v.fitting
        }))
        const rfid = new RFIDGenerator(mapped)
        const generated = await rfid.generateWithBatch()
        const timeStringFormat = this.$dayjs().format('DD_MM_YYYY_HHmm')
        const options = {
          filename: `${this.store.uniqueCode}_RFID_${timeStringFormat}`,
          showLabels: true,
          useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(generated)
      } catch (error) {
        console.error('exportCsv', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>

<style scoped>
.rfid-table {
  height: calc(100vh - 190px);
}
</style>

<template>
  <v-container
    fluid
    class="pa-4">
    <v-card
      v-if="items.length > 0"
      ref="release-note-list"
      class="pa-4">
      <h1 class="font-weight-light">
        Change Logs (Release Note)
      </h1>
      <v-timeline dense>
        <v-timeline-item
          v-for="(item, i) in items"
          :key="i"
          :color="item.color"
          class="my-2 py-2"
          :style="!item.accepted ? `background-color: ${item.color}1A; border-left: 4px solid ${item.color};
          border-radius: 4px;` : ''"
          dense
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="9"
              sm="9">
              <h2
                :class="'headline font-weight-light'"
                :style="`color: ${item.color}`">
                {{ item.title }}
                <v-chip
                  class="mb-1"
                  :color="getReleaseNoteTypeColor(item.type)"
                  text-color="white"
                  small>
                  {{ item.type }}
                </v-chip>
              </h2>
            </v-col>
            <v-col
              cols="12"
              md="3"
              sm="3"
              :class="`${$vuetify.breakpoint.xsOnly ? '' : 'text-right'} px-2`">
              <v-btn
                v-if="!item.accepted"
                color="success"
                @click="acknowledgeReleaseNoteHandler(item.id)"
              >
                รับทราบ
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12">
              <span class="text-block caption"> {{ item.dateString }}</span>
            </v-col>
            <v-col
              cols="12"
              md="12">
              <div class="mt-2 text-block">
                {{ item.detail }}
              </div>
            </v-col>

            <v-col
              v-for="(image, index) in item.images"
              :key="index"
              cols="12"
              lg="3"
              md="6"
              class="image-preview-wrapper pa-2"
              @click="selectImagePreview(item.images, index)">
              <img
                class="image-preview"
                :src="image"
                alt="">
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>

      <p
        v-if="showLoadMore"
        class="text-center">
        <a @click="getReleaseNote">More </a>
      </p>
    </v-card>
    <MultipleImagePreview
      v-if="modalPreview"
      v-model="selectPreviewIndex"
      :images="selectPreviewImage"
      @onClose="modalPreview = false " />
  </v-container>
</template>

<script>
import ReleaseNoteProvider from '@/resources/ReleaseNoteProvider'
import MultipleImagePreview from '@/components/MultipleImagePreview.vue'
import { getRole } from '@/assets/js/Authentication'
import { mapActions } from 'vuex'

const ReleaseNoteService = new ReleaseNoteProvider()
export default {
  name: 'Home',
  components: { MultipleImagePreview },
  data () {
    return {
      types: [
        {
          name: 'FEATURE',
          value: 'FEATURE',
          color: '#4CAF50'
        },
        {
          name: 'CHANGED',
          value: 'CHANGED',
          color: '#9C27B0'
        },
        {
          name: 'FIXED',
          value: 'FIXED',
          color: '#E91E63'
        },
        {
          name: 'ISSUE',
          value: 'ISSUE',
          color: '#F44336'
        },
        {
          name: 'ANNOUNCEMENT',
          value: 'ANNOUNCEMENT',
          color: '#03A9F4'
        },
        {
          name: 'NOTE',
          value: 'NOTE',
          color: '#607D8B'
        }
      ],
      page: 0,
      total: 0,
      items: [],
      loading: false,
      modalPreview: false,
      selectPreviewImage: [],
      selectPreviewIndex: 0
    }
  },
  computed: {
    showLoadMore () {
      return this.total > this.items.length
    }
  },
  async created () {
    await this.getReleaseNote()
  },
  methods: {
    ...mapActions({
      acknowledgeReleaseNote: 'Notification/acknowledgeReleaseNote'
    }),
    async acknowledgeReleaseNoteHandler (id) {
      try {
        await this.acknowledgeReleaseNote(id)
        const index = this.items.findIndex((item) => item.id === id)
        if (index !== -1) {
          this.items[index].accepted = true
        }
      } catch (error) {
        console.error('Error acknowledging release note:', error)
      }
    },
    getReleaseNoteTypeColor (type) {
      const colors = {}
      this.types.forEach((t) => { colors[t.name] = t.color })

      return colors[type] || '#607D8B'
    },
    selectImagePreview (images, index = 0) {
      this.selectPreviewImage = images
      this.selectPreviewIndex = index
      this.modalPreview = true
    },
    async getReleaseNote () {
      try {
        this.loading = true
        this.page += 1
        const role = getRole()
        const { data } = await ReleaseNoteService.getReleaseNoteByUser({
          role,
          sortBy: 'dateString',
          sortOrder: 'desc',
          page: this.page,
          limit: 25
        })
        this.total = data.total
        this.items = [...this.items, ...data.results.map((re) => ({
          ...re,
          color: this.getReleaseNoteTypeColor(re.type)
        }))]
      } catch (error) {
        console.error('getReleaseNote: ', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>
.text-block {
  white-space: pre-wrap;
  color: #6d6d6d;
}
.image-preview-wrapper {
  height: 250px;
  padding: 3px;
  margin: 5px 0;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #000;
}
.image-preview {
  max-width: 100%;
  max-height: 100%;
}
</style>

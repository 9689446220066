<template>
  <v-main>
    <div
      size="A4"
      class="printed page-a4 container">
      <div
        v-for="(sku, index) in printItems"
        :key="index"
        size="30x55">
        <div
          v-if="sku.offset"
          class="content-header">
        </div>
        <div
          v-else
          class="content-header">
          <div class="img-header">
            <img :src="sku.qr">
          </div>
          <div class="variant ml-2">
            <div class="inner-variant">
              {{ sku.consumerType }}
            </div>
          </div>
          <div class="variant">
            <div class="inner-variant">
              {{ sku.skuCode }}
            </div>
          </div>
          <div class="variant">
            <div class="inner-variant text-uppercase">
              {{ sku.options }}
            </div>
          </div>
          <div
            v-if="sku.fitting"
            class="variant">
            <div class="inner-variant text-uppercase">
              {{ sku.fitting }}
            </div>
          </div>
          <div
            v-if="sku.gwCollection"
            class="variant">
            <div class="inner-variant">
              {{ sku.gwCollection }}
            </div>
          </div>
          <div class="variant">
            <div class="inner-variant">
              วันที่ผลิต : {{ sku.productionDate }}
            </div>
          </div>
          <div class="variant ml-3">
            <div class="inner-variant variant-price">
              {{ priceFormat(sku.price) }} THB
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
import { getPrintItem } from '@/assets/js/helper'

export default {
  name: 'QRcodeA4Page',
  data () {
    return {
      printItems: []
    }
  },
  async mounted () {
    const products = await getPrintItem()
    products.forEach((_sku) => {
      let sku = _sku
      for (let index = 0; index < sku.amount; index++) {
        sku = JSON.parse(JSON.stringify(sku))
        // const productName = sku.productName.split('-')
        // sku.productName = productName.length ? productName[productName.length - 1] : sku.productName
        this.printItems.push(sku)
      }
    })
    await window.print()
  },
  methods: {
    priceFormat (price) {
      return parseFloat(price).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>

<style scoped>
  div[size="A4"] {
    background: #fff;
    width: 210mm;
    min-height: 297mm;
    display: block;
    margin: 40px auto;
    padding: 14mm 15mm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    color: #000;
  }
  div[size="30x55"] {
    display: inline-table;
    padding: 3mm;
    width: 55mm;
    height: 30mm;
    /* margin: 0 2.5mm; */
    margin: 0 1.5mm;
    background: #fff;
    color: #000;
    font-size: 7px;
    line-height: 12px;
    font-weight: bold;
    overflow: hidden;
  }
  div[size="30x55"] .content-header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  div[size="30x55"] .variant {
    position: relative;
    font-weight: normal;
    width: 12px;
    height: 100%;
    overflow: hidden;
    text-overflow: clip;
  }
  div[size="30x55"] .variant .inner-variant {
    width: 20mm;
    height: 12px;
    vertical-align: middle;
    overflow: hidden;
    position: absolute;
    z-index: 9999;
    left: 12px;
    bottom: 7px;
    transform-origin: left bottom;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  div[size="30x55"] .img-header {
    padding-top: 6px;
    margin-bottom: 12px;
    height: 20mm;
    width: 20mm;
  }
  div[size="30x55"] .img-header img {
    width: 100%;
    height: auto;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .variant-price {
    font-size: 11px;
  }
  @media print {
    div[size="A4"] {
      width: 190mm;
      min-height: 276mm;
      padding: 2.5mm 5mm;
      zoom: 100%;
      margin: 0;
      box-shadow: none;
    }
  }
</style>

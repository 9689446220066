<template>
  <v-dialog
    v-model="active"
    width="70%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Upload CSV File</span>
      </v-card-title>
      <v-card-text>
        <v-file-input
          :key="componentKey"
          v-model="file"
          label="Choose CSV file"
          accept=".csv"
          dense
          outlined
          @change="handlePreview"
        />
        <v-alert
          v-if="messages"
          :type="messages_type || 'info'"
          dense
          text
        >
          {{ messages }}
        </v-alert>
        <v-row>
          <v-col
            cols="12"
            class="btn-download-template"
          >
            <button
              @click="downloadTemplate"
            >
              Download Template
            </button>
          </v-col>
        </v-row>
        <v-data-table
          v-if="previewData.length > 0"
          :headers="headers"
          :items="previewData"
          :loading="loading"
          :hide-default-footer="true"
          :items-per-page="-1"
          fixed-header
          dense
          class="elevation-1 mt-3"
          style="max-height: 300px; overflow-y: scroll;"
        >
          <template v-slot:item="{ item }">
            <tr :class="{ 'error-row': hasError(item) }">
              <td
                v-for="header in headers"
                :key="header.value"
                :class="{
                  'error-cell': isInvalidCell(item, header.value),
                  'error-row-cell': hasError(item),
                }"
              >
                {{ item[header.value] }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!canUpload"
          :class="{ 'upload-disabled': !canUpload }"
          :loading="loading"
          @click="handleUpload"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CelebProvider from '@/resources/CelebProvider'
import { ExportToCsv } from 'export-to-csv'
import { mapActions } from 'vuex/dist/vuex.common'

const CelebService = new CelebProvider()

export default {
  name: 'ImportCsvModalPreview',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: this.value,
      file: null,
      previewData: [],
      duplicateNamesFromBackend: new Set(),
      headers: [
        { text: 'Name', value: 'Name', width: '150px' },
        { text: 'Email', value: 'Email', width: '100px' },
        { text: 'Tel', value: 'Tel', width: '100px' },
        { text: 'Instagram', value: 'Instagram', width: '150px' },
        { text: 'Facebook', value: 'Facebook', width: '150px' },
        { text: 'Tiktok', value: 'Tiktok', width: '150px' },
        { text: 'X / Twitter', value: 'X / Twitter', width: '150px' },
        { text: 'Shipping FirstName', value: 'Shipping FirstName', width: '180px' },
        { text: 'Shipping LastName', value: 'Shipping LastName', width: '180px' },
        { text: 'Shipping Email', value: 'Shipping Email', width: '100px' },
        { text: 'Shipping Address 1', value: 'Shipping Address 1', width: '350px' },
        { text: 'Shipping Address 2', value: 'Shipping Address 2', width: '350px' },
        { text: 'Sub-District', value: 'Sub-District', width: '350px' },
        { text: 'District', value: 'District', width: '100px' },
        { text: 'State Province', value: 'State Province', width: '150px' },
        { text: 'Country', value: 'Country', width: '100px' },
        { text: 'Postal Code', value: 'Postal Code', width: '120px' }
      ],
      componentKey: 0,
      messages: '',
      messages_type: 'info',
      loading: false
    }
  },
  computed: {
    hasErrors () {
      return this.previewData.some((row) => this.hasError(row))
    },
    canUpload () {
      return this.previewData.length > 0 && !this.hasErrors && this.file !== null
    }
  },
  watch: {
    value (val) {
      this.active = val
    },
    active (val) {
      this.$emit('input', val)
      if (!val) {
        this.resetForm()
      }
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    hasError (row) {
      return this.isInvalidRow(row) || this.isDuplicateName(row)
    },
    isDuplicateName (row) {
      return (
        this.duplicateNames.has(row['Name'])
        || this.duplicateNamesFromBackend.has(row['Name'])
      )
    },
    isInvalidRow (row) {
      return !row['Name'] || row['Name'].trim() === ''
            || !row['Shipping FirstName'] || row['Shipping FirstName'].trim() === ''
            || !row['Shipping LastName'] || row['Shipping LastName'].trim() === ''
            || !row['Shipping Address 1'] || row['Shipping Address 1'].trim() === ''
            || !row['Sub-District'] || row['Sub-District'].trim() === ''
            || !row['District'] || row['District'].trim() === ''
            || !row['State Province'] || row['State Province'].trim() === ''
            || !row['Country'] || row['Country'].trim() === ''
            || !row['Postal Code'] || row['Postal Code'].trim() === ''
    },
    isInvalidCell (row, field) {
      const requiredField = this.headers.find((h) => h.value === field)?.required
      return requiredField && (!row[field] || row[field].trim() === '')
    },
    isDuplicateNameCell (row, field) {
      return field === 'Name' && this.isDuplicateName(row)
    },
    findDuplicateNames (data) {
      const nameCount = {}
      const duplicates = new Set()

      data.forEach((row) => {
        const name = row['Name']
        if (name) {
          nameCount[name] = (nameCount[name] || 0) + 1
        }
      })

      Object.entries(nameCount).forEach(([name, count]) => {
        if (count > 1) {
          duplicates.add(name)
        }
      })

      return duplicates
    },
    close () {
      this.active = false
      this.resetForm()
    },
    downloadTemplate () {
      const headers = this.headers.map((header) => header.text)

      const templateData = [Object.fromEntries(headers.map((header) => [header, '']))]

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        filename: 'template',
        useTextFile: false,
        useBom: true,
        headers
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(templateData)
    },

    convertToCSV (data) {
      return data.map((row) => row.join(',')).join('\n')
    },

    handlePreview () {
      this.loading = true
      this.messages = null
      if (!this.file) {
        this.messages = 'No file selected. Please choose a CSV file.'
        this.messages_type = 'error'
        this.resetForm()
        this.loading = false
        return
      }
      this.readCSV(this.file)
      this.loading = false
    },
    readCSV (file) {
      if (!(file instanceof Blob)) {
        this.messages = 'Invalid file format. Please upload a CSV file.'
        this.messages_type = 'error'
        return
      }

      this.previewData = []
      const reader = new FileReader()
      reader.onload = (e) => {
        const arrayBuffer = e.target.result
        const encodings = ['utf-8']
        let decodedText = ''

        for (const encoding of encodings) {
          try {
            const decoder = new TextDecoder(encoding)
            decodedText = decoder.decode(arrayBuffer)
          } catch (error) {
            console.warn(`Failed to decode with ${encoding}, trying next...`)
          }
        }
        this.parseCSV(decodedText)
      }
      reader.readAsArrayBuffer(file)
    },

    parseCSV (csvData) {
      const processedData = csvData
        .split('\n')
        .map((row) => row.split(',')
            .map((origValue) => {
              const value = origValue.trim()
              return value.includes('"') ? value : `"${value}"`
            })
            .join(','))
        .join('\n')

      const rows = processedData.trim().split('\n')
      const headers = rows[0].split(',').map((header) => header.trim().replace(/^"|"$/g, ''))

      const missingColumns = this.headers
        .map((header) => header.text)
        .filter((requiredColumn) => !headers.includes(requiredColumn))

      const extraColumns = headers.filter((column) => !this.headers.map((header) => header.text).includes(column))

      if (missingColumns.length > 0 || extraColumns.length > 0) {
        this.messages = 'Invalid CSV file.'
        if (missingColumns.length > 0) {
          this.messages += ` Missing columns: ${missingColumns.join(', ')}.`
        }
        if (extraColumns.length > 0) {
          this.messages += ` Extra columns: ${extraColumns.join(', ')}.`
        }
        this.messages_type = 'error'
        return
      }

      this.previewData = rows.slice(1).map((row) => {
        const cells = this.splitCSVRow(row)
        const obj = {}

        headers.forEach((header, index) => {
          if (header) {
            const formattedCell = cells[index]
              ? cells[index].replace(/^="|"$|="$/, '').trim()
              : ''

            obj[header] = formattedCell
          }
        })

        return obj
      }).filter((row) => Object.values(row).some((value) => value !== ''))

      const invalidRows = this.previewData.filter((row) => this.isInvalidRow(row))
      this.duplicateNames = this.findDuplicateNames(this.previewData)

      if (invalidRows.length > 0 || this.duplicateNames.size > 0) {
        const messageparts = []
        if (invalidRows.length > 0) {
          messageparts.push('Some rows are missing required fields')
        }
        if (this.duplicateNames.size > 0) {
          messageparts.push(`Duplicate names detected: ${[...this.duplicateNames].join(', ')}`)
        }
        this.messages = messageparts.join('. ')
        this.messages_type = 'error'
        return
      }
        this.messages = null

      this.previewData = this.previewData.map((row) => {
        if (row['Country']) {
          const formattedCountry = row['Country']
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')

          return { ...row, Country: formattedCountry }
        }
        return row
      })

      if (this.previewData.length === 0) {
        this.messages = 'The uploaded file contains no data.'
        this.messages_type = 'error'
        return
      }

      this.messages = null
    },

    splitCSVRow (row) {
      const regex = /(".*?"|[^",\n]+)(?=\s*,|\s*$)/g
      const cells = []
      let match = regex.exec(row)

      while (match !== null) {
        cells.push(match[1].replace(/^"|"$/g, ''))
        match = regex.exec(row)
      }

      return cells
    },

    async handleUpload () {
      if (!this.canUpload) {
        return
      }

      try {
        this.loading = true
        const payload = this.previewData.map((data) => {
          const address = {
            contactNo: data['Tel'],
            firstName: data['Shipping FirstName'],
            lastName: data['Shipping LastName'],
            address: data['Shipping Address 1'],
            address2: data['Shipping Address 2'],
            subDistrict: data['Sub-District'],
            district: data['District'],
            stateProvince: data['State Province'],
            country: data['Country'],
            postcode: data['Postal Code'],
            email: data['Shipping Email']
          }

          const socialMedias = [
            { platform: 'instagram', accountName: data['Instagram'] },
            { platform: 'facebook', accountName: data['Facebook'] },
            { platform: 'tiktok', accountName: data['Tiktok'] },
            { platform: 'x/twitter', accountName: data['X / Twitter'] }
          ].filter((socialMedia) => socialMedia.accountName)

          return {
            name: data['Name'],
            email: data['Email'],
            tel: data['Tel'],
            address,
            socialMedias
          }
        })

        const { data } = await CelebService.importCelebs(payload)

        this.duplicateNamesFromBackend = new Set(data.duplicates)

        if (data) {
          if (data.importedCount > 0) {
            this.setSnackbar({
              value: true,
              message: `Successfully imported ${data.importedCount} celebs.`,
              type: 'success'
            })
            if (data.duplicates && data.duplicates.length > 0) {
              this.setSnackbar({
                value: true,
                message: `Duplicate celebs that were not imported: ${data.duplicates.join(', ')}`,
                duration: 10000,
                type: 'warning'
              })
            }
            this.close()
            this.$emit('imported')
          } else if (data.importedCount === 0) {
            this.messages = 'No celebs were imported.'
            if (data.duplicates && data.duplicates.length > 0) {
              this.messages += `\nDuplicate entries detected: ${data.duplicates.join(', ')}`
            }
            this.messages_type = 'error'
          }
        }
        this.loading = false
      } catch (error) {
        this.messages = error.message
        this.messages_type = 'error'
        this.loading = false
      }
    },

    resetForm () {
      this.file = null
      this.messages_type = null
      this.previewData = []
      this.messages = ''
      this.componentKey++
      this.duplicateNamesFromBackend = new Set()
    }
  }
}
</script>

<style scoped>
  .btn-download-template {
    display: flex;
    justify-content: flex-end;
  }
  button {
    color: dodgerblue;
  }
  .success-message {
    color: green;
    font-size: 15px;
  }
  .error-row {
    background-color: rgba(255, 0, 0, 0.05);
  }
  .error-row-cell {
    color: #FF5252 !important;
  }
  .error-cell {
    color: #D32F2F !important;
    font-weight: bold;
  }
</style>

<template>
  <v-container
    fluid
    white>
    <v-row class="pa-8 elevation-2">
      <v-col
        cols="12"
        class="card-container">
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="card-title">
            E-TAX And VRT Setting
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-end align-center">
            <v-btn
              color="secondary"
              :loading="loading"
              @click="showETaxAndVRTModal()">
              <v-icon>mdi-plus</v-icon>
              Add
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="header"
              :items="formData.pickUpWarehouses"
              :items-per-page="-1"
              :loading="loading"
              class="elevation-1"
              fixed-header
              hide-default-footer>
              <template #[`item.actions`]="{ item }">
                <div class="d-flex align-center">
                  <v-icon
                    class="mr-2"
                    @click="showETaxAndVRTModal(item)">
                    mdi-pencil
                  </v-icon>
                  <gw-icon-hover
                    small
                    class="sku-table-bin"
                    icon-name="mdi-delete"
                    color="red"
                    icon-hover="mdi-delete-empty"
                    @click="openDeleteDailogConfirm(item)" />
                </div>
              </template>
              <template #[`item.eTax`]="{ item }">
                <v-icon
                  v-if="item.eTax"
                  color="success">
                  mdi-check
                </v-icon>
                <v-icon
                  v-else
                  color="error">
                  mdi-close
                </v-icon>
              </template>
              <template #[`item.vrt`]="{ item }">
                <v-icon
                  v-if="item.vrt"
                  color="success">
                  mdi-check
                </v-icon>
                <v-icon
                  v-else
                  color="error">
                  mdi-close
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ETaxAndVrtModal
      v-model="active"
      :edit-item="selectedItem"
      :selected-warehouses="selectedWarehouses"
      @submit="addPickUpWarehouse($event)"
      @clear:edit="selectedItem = null"
    />
  </v-container>
</template>

<script>
import IBoxWarehouseSettingProvider from '@/resources/IBoxWarehouseSettingProvider'
import ETaxAndVrtModal from '../components/ETaxAndVrtModal.vue'
import { mapActions } from 'vuex'

const iBoxWarehouseSettingService = new IBoxWarehouseSettingProvider()

export default {
  components: {
    ETaxAndVrtModal
  },
  data () {
    return {
      loading: false,
      active: false,
      formData: {
        code: '',
        name: '',
        eTax: false,
        vrt: false,
        pickUpWarehouses: []
      },
      header: [
        {
          text: 'Warehouse Code',
          value: 'warehouse.code',
          sortable: false
        },
        {
          text: 'Warehouse Name',
          value: 'warehouse.name',
          sortable: false
        },
        {
          text: 'E-TAX',
          value: 'eTax',
          sortable: false
        },
        {
          text: 'VRT',
          value: 'vrt',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      warehouseETaxAndVrtData: [],
      selectedItem: null
    }
  },
  computed: {
    selectedWarehouses () {
      return this.formData.pickUpWarehouses.map((item) => item.warehouse)
    }
  },
  mounted () {
    this.getStoreSetting()
  },
  methods: {
    ...mapActions({
      setModal: 'Components/setModal'
    }),
    showETaxAndVRTModal (item = null) {
      this.selectedItem = item
      this.active = true
    },
    addPickUpWarehouse (data) {
      if (!data) {
        this.getStoreSetting()
        return
      }

      let index = this.formData.pickUpWarehouses.findIndex((item) => data.warehouse === item.warehouse)

      if (this.selectedItem) {
        index = this.formData.pickUpWarehouses.findIndex((item) => this.selectedItem.warehouse === item.warehouse)
      }

      if (index === -1) {
        this.formData.pickUpWarehouses[index] = { ...data }
        this.getStoreSetting()
      } else {
        this.formData.pickUpWarehouses.push(data)
        this.getStoreSetting()
      }
      this.selectedItem = null
    },
    async removePickUpWarehouse (item) {
      try {
        this.loading = true

        const { data } = await iBoxWarehouseSettingService.deleteIBoxWarehouseSetting(item.warehouse.id)

        if (data) {
          this.getStoreSetting()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async getStoreSetting () {
      this.loading = true
      try {
        this.loading = true

        const { data } = await iBoxWarehouseSettingService.getIBoxWarehouseSetting()

        this.formData.pickUpWarehouses = data

        this.loading = false
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    openDeleteDailogConfirm (deleteItem) {
        this.setModal({
          value: true,
          title: 'ยืนยันการลบ',
          message: 'ต้องการลบการตั้งค่า I-BOX ของสาขานี้ใช่หรือไม่',
          confirmText: 'ต้องการ',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'ไม่ต้องการ',
          onConfirm: () => this.removePickUpWarehouse(deleteItem)
        })
    }
  }
}
</script>

<style scoped>
.card-title {
  font-weight: bold;
  font-size: 1.35rem;
}
</style>

<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row>
      <v-col cols="3">
        <v-row class="products-row">
          <v-col cols="12">
            <side-bar
              :products="products"
              :fetching-product="gettingProducts"
              @input:offset="setOffset($event)"
              @add-product="addProductToSelected($event)"
              @search="getProducts($event)" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="9">
        <v-row class="products-row">
          <v-col cols="12">
            <qr-data-table
              :is-show-fit="isShowFit"
              :products="selectedProduct"
              :offset="offset"
              @remove-by-index="removeSelected($event)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { generateQRCode } from '@/assets/js/helper'
import ProductProvider from '@/resources/ProductProvider'
import StoreProvider from '@/resources/StoreProvider'
import ConsumerTypes from '@/assets/js/ConsumerTypes'
import SideBar from '../components/sideBar.vue'
import qrDataTable from '../components/qrDataTable.vue'

const StoreService = new StoreProvider()
const ProductService = new ProductProvider()
export default {
  components: {
    SideBar,
    qrDataTable
  },
  data () {
    return {
      gettingProducts: false,
      selectedProduct: [],
      products: [],
      offset: 0,
      webUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      store: 'Store/store'
    }),
    isShowFit () {
      return this.store?.id === '645a1d00765d5519beeb97f6'
    }
  },
  async mounted () {
    await this.getProducts()
    await this.getWebUrl()
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    async getProducts (search = '') {
      try {
        this.gettingProducts = true
        const { data } = await ProductService.getProducts({ search })
        this.products = this.mappingSkus(data.results)
      } catch (error) {
        console.error('getting product', error)
        this.setErrorPage(error.code)
      } finally {
        this.gettingProducts = false
      }
    },
    setOffset (value) {
      this.offset = value
    },
    mappingSkus (products) {
      const allSkus = []
      products.forEach((product) => {
        const tempSkus = product.skus.map((sku) => ({
          ...sku,
          name: product.name,
          model: product.model,
          gwCollection: product.gwCollection,
          prototypeCategory: product.productPrototype?.category || 'UNKNOWN',
          tags: product.tags
        }))

        allSkus.push(...tempSkus)
      })

      const mapped = allSkus.map((sku) => {
        const skuCode = sku.code
        const merCode = sku.name.split('-')
        const productName = merCode[merCode.length - 1]
        const options = this.initOptions(sku.size, sku.color, sku.tags)
        const { price } = sku
        const fitting = this.getFitting(sku.tags)

        return {
          skuCode,
          productName,
          gwCollection: sku.gwCollection?.nickname ? sku.gwCollection?.nickname : 'COLLECTION : -',
          prototypeCategory: sku.prototypeCategory,
          options,
          fitting,
          price
        }
      })

      return mapped
    },
    getFitting (tags) {
      if (this.isShowFit) {
        if (tags && tags.length) {
          const fittingTag = tags.find((tag) => ['UNISEX', 'WOMEN', 'KIDS', 'MEN'].includes(tag.toUpperCase()))
          if (fittingTag) {
            return `${fittingTag.toUpperCase()}`
          }
        }
        return 'FIT : -'
      }
      return ''
    },
    initOptions (size, color) {
      let option = ''
      if (size.name && color.name) {
        option = `${color.name.toUpperCase()} - ${size.name.toUpperCase()}`
      } else if (size.name && !color.name) {
        option = size.name.toUpperCase()
      } else if (!size.name && color.name) {
        option = color.name.toUpperCase()
      }

      return option
    },
    async getWebUrl () {
      const { data: { webUrl } } = await StoreService.getStoreSetting()
      this.webUrl = webUrl
    },
    async addProductToSelected (_product) {
      if (this.isShowFit && _product.fitting === 'FIT : -') {
        this.setSnackbar({
          value: true,
          message: 'รายการสินค้านี้ไม่ได้ระบุ Fit กรุณาตรวจสอบ',
          type: 'error',
          timeout: 6000
        })
      }
      const qr = await generateQRCode(`${(this.webUrl)}/${_product.skuCode}`)
      const product = {
        ..._product,
        qr,
        consumerType: ConsumerTypes(_product.prototypeCategory.toLowerCase()),
        productionDate: this.$dayjs().subtract(2, 'month').format('MM-YYYY'),
        fitting: _product.fitting
      }
      const duplicated = this.selectedProduct.findIndex((selected) => selected.skuCode === product.skuCode)
      if (duplicated === -1) {
        this.selectedProduct.push({ ...product, amount: 1 })
      } else {
        this.selectedProduct[duplicated].amount++
      }
    },
    removeSelected (index) {
      this.selectedProduct.splice(index, 1)
    }
  }
}
</script>

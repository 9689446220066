<template>
  <v-dialog
    v-model="modal"
    max-width="500px"
    class="rounded-lg"
    @click:outside="clearForm()">
    <v-card class="rounded-lg">
      <v-card-title>
        <span
          class="py-1"
          style="font-size: 1.75rem; font-weight: 500">
          {{ EditMode ? 'Edit' : 'Add' }} E-Tax and VRT
        </span>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="modal-form"
          tag="form"
          @submit.prevent="onSubmit()">
          <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="Warehouse"
                :rules="{ required: true }">
                <WarehouseSelect
                  v-model="formData.warehouse"
                  :disabled="EditMode"
                  :items="filterWarehouses"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ offsetY: true }"
                  :rules="[
                    (v) => !!v || 'Warehouse is required'
                  ]"
                  label="Warehouse*"
                  outlined
                />
                <p
                  v-if="errors.length > 0"
                  class="red--text mt-2">
                  {{ errors[0] }}
                </p>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <span
                style="font-size: 1rem; font-weight: 500"
              >E-TAX</span>
            </v-col>
            <v-col
              cols="6"
              class="d-flex justify-end">
              <label class="switch">
                <input
                  v-model="formData.eTax"
                  type="checkbox">
                <span class="slider"></span>
              </label>
            </v-col>
            <v-col cols="6">
              <span
                style="font-size: 1rem; font-weight: 500"
              >VAT REFUND</span>
            </v-col>
            <v-col
              cols="6"
              class="d-flex justify-end">
              <label class="switch">
                <input
                  v-model="formData.vrt"
                  type="checkbox">
                <span class="slider"></span>
              </label>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-end align-center"
              style="gap: 0.5rem;">
              <v-btn
                color="error"
                text
                @click="clearForm()">
                Cancel
              </v-btn>
              <v-btn
                :loading="saving"
                type="submit"
                color="success"
                text>
                Save
              </v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import WarehouseProvider from '@/resources/WarehouseProvider'
import IBoxWarehouseSettingProvider from '@/resources/IBoxWarehouseSettingProvider'
import { mapActions } from 'vuex'

const WarehouseService = new WarehouseProvider()
const iBoxWarehouseSettingService = new IBoxWarehouseSettingProvider()

export default {
  components: {
    WarehouseSelect
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    editItem: {
      type: Object,
      default: () => null
    },
    selectedWarehouses: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: true,
      modal: this.value,
      formData: {
        warehouse: null,
        eTax: false,
        vrt: false
      },
      warehouses: [],
      textBoxRules: [
        (v) => !!v || 'This field is required'
      ],
      EditMode: false,
      saving: false
    }
  },
  computed: {
    filterWarehouses () {
      return this.warehouses.filter((warehouse) => !this.selectedWarehouses.find((item) => (
        this.formData.warehouse !== item.id && item.id === warehouse.id
      )))
    }
  },
  watch: {
    value (value) {
      this.modal = value
    },
    modal (value) {
      this.$emit('input', value)
    },
    editItem (value) {
      if (value) {
        this.EditMode = true
        this.formData = {
          warehouse: value.warehouse.id,
          eTax: value.eTax,
          vrt: value.vrt
        }
      } else {
        this.EditMode = false
        this.formData = {
          warehouse: null,
          eTax: false,
          vrt: false
        }
      }
    }
  },
  mounted () {
    this.getWarehouses()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    updateValue (val) {
      this.$emit('input', val)
    },
    async getWarehouses () {
      try {
        this.loading = true
        const { data } = await WarehouseService.getWarehouse()
        this.warehouses = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.saving = true
        const isValid = await this.$refs['modal-form'].validate()
        if (this.EditMode) {
          await iBoxWarehouseSettingService.updateIBoxWarehouseSetting(this.editItem.warehouse.id, this.formData)
          this.$emit('submit')
          this.modal = false
        } else if (isValid) {
          const { data } = await iBoxWarehouseSettingService.createIBoxWarehouseSetting({
            ...this.formData,
            warehouse: this.formData.warehouse
          })

          if (data) {
            this.$emit('submit', data)
            this.clearForm()
            this.modal = false
          }
        }
      } catch (error) {
        console.error('Error during form submission:', error)
      } finally {
        this.saving = false
      }
    },
    clearForm () {
      this.modal = false
      this.EditMode = false
      this.$emit('clear:edit')
      this.formData = {
        warehouse: null,
        eTax: false,
        vrt: false
      }
    }
  }
}
</script>

<style scoped>
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(182, 182, 182);
  transition: .4s;
  border-radius: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 8px;
  left: 0.3em;
  bottom: 0.3em;
  transform: rotate(270deg);
  background-color: rgb(255, 255, 255);
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: #21cc4c;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
  transform: translateX(1.5em);
}
</style>

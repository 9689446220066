export default {
  path: '/new-report',
  component: () => import('@/views/newReport/index.vue'),
  children: [
    {
      path: 'sale-report',
      name: 'NewSaleReport',
      component: () => import('@/views/newReport/pages/SaleReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'merchandising_planner',
          'merchandiser',
          'accounting'
        ]
      }
    },
    {
      path: 'group-categories',
      name: 'NewReportCategories',
      component: () => import('@/views/newReport/pages/NewDashboardGroupCat.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'developer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'store_manager',
          'assist_store_manager'
        ]
      }
    },
    {
      path: 'cashier-report',
      name: 'NewCashierReport',
      component: () => import('@/views/newReport/pages/CashierReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'area_manager',
          'developer'
        ]
      }
    },
    {
      path: 'pos-payment-summary',
      name: 'PosPaymentSummary',
      component: () => import('@/views/newReport/pages/PosPaymentSummary.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'accounting',
          'area_manager',
          'developer',
          'merchandising_planner',
          'merchandiser'
        ]
      }
    },
    {
      path: 'admin-report',
      name: 'NewAdminReport',
      component: () => import('@/views/newReport/pages/AdminReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'developer'
        ]
      }
    },
    {
      path: 'pos-report',
      name: 'NewPosReport',
      component: () => import('@/views/newReport/pages/PosReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'merchandising_planner',
          'merchandiser',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'accounting'
        ]
      }
    },
    {
      path: 'sale-report-international',
      name: 'NewSaleReportInternational',
      component: () => import('@/views/newReport/pages/SaleReportInternational.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'merchandising_planner',
          'merchandiser',
          'accounting'
        ]
      }
    },
    {
      path: 'sale-report-singapore',
      name: 'NewSaleReportSingapore',
      component: () => import('@/views/newReport/pages/SaleReportInternationalSingapore.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'merchandising_planner',
          'merchandiser',
          'accounting'
        ]
      }
    },
    {
      path: 'fulfillment-report',
      name: 'NewFulfillmentReport',
      component: () => import('@/views/newReport/pages/FulfillmentReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'merchandising_planner',
          'accounting',
          'marketing',
          'developer'
        ]
      }
    },
    {
      path: 'fulfillment-report-international',
      name: 'NewFulfillmentReportInternational',
      component: () => import('@/views/newReport/pages/FulfillmentReportInternational.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'merchandising_planner',
          'accounting',
          'marketing',
          'developer'
        ]
      }
    },
    {
      path: 'sale-product',
      name: 'SaleProductReport',
      component: () => import('@/views/newReport/pages/SaleProductReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'accounting'
        ]
      }
    },
    {
      path: 'pos-sale-product',
      name: 'PosSaleProductReport',
      component: () => import('@/views/newReport/pages/PosSaleProductReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'inventory_and_costing',
          'merchandising_planner',
          'warehouse_manager',
          'accounting_manager',
          'merchandiser',
          'accounting',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager'
        ]
      }
    }
  ]
}

<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="items"
    :options.sync="paginate"
    :server-items-length="paginate.total"
    :footer-props="dataTableFooter"
    loading-text="กรุณารอสักครู่...">
    <template v-slot:[`item.socialMedias`]="{ item }">
      <div
        v-for="(social, index) in item.socialMedias"
        :key="`social-media-${index}`"
        class="d-flex">
        <v-icon v-if="socialMediaCompute(social).icon">
          {{ socialMediaCompute(social, index).icon }}
        </v-icon>
        <p v-else>
          {{ social.platform }}
        </p>
        <span>{{ socialMediaCompute(social, index).text }}</span>
      </div>
    </template>
    <template v-slot:[`item.address`]="{ item }">
      {{ getAddress(item.address) }}
    </template>
    <template
      v-slot:[`item.action`]="{ item }">
      <v-btn
        icon
        small
        @click="editCeleb(item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        icon
        small
        @click="removeCeleb(item.id)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name', width: '20%' },
        { text: 'Social Medias', value: 'socialMedias', width: '30%' },
        { text: 'Address', value: 'address', width: '40%' },
        { text: 'Action', value: 'action', sortable: false, width: '10%' }
      ],
      dataTableFooter: {
        itemsPerPageText: 'จำนวนแสดงต่อหน้า',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      }
    }
  },
  computed: {
    paginate: {
      get () {
        return this.value
      },
      set (_value) {
        const value = { ..._value, page: _value.page || 1 }
        this.$emit('input', value)
      }
    }
  },
  methods: {
    removeCeleb (id) {
      this.$emit('click:remove', id)
    },
    editCeleb (item) {
      this.$emit('click:edit', item)
    },
    socialMediaCompute ({ accountName, platform }, index = null) {
      let text = ''
      let icon = null

      if (index === 0) {
        text += `: ${accountName}`
      }

      if (index !== 0) {
        text += `\n: ${accountName}`
      }

      const socialMedia = {
        'instagram': 'mdi-instagram',
        'facebook': 'mdi-facebook',
        'tiktok': 'mdi-music-note-outline',
        'x/twitter': 'mdi-twitter'
      }

      icon = socialMedia[platform] ?? 'mdi-account'

      return { icon, text }
    },
    getAddress (addressObj) {
      const fields = [
        addressObj.address,
        addressObj.address2,
        addressObj.states,
        addressObj.subDistrict,
        addressObj.district,
        addressObj.stateProvince,
        addressObj.province,
        addressObj.country,
        addressObj.postcode
      ]

      return fields.filter((field) => field).join(' ')
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="body">
    <div class="filter-bar">
      <v-row>
        <v-col
          cols="3"
          class="filter-bar-title">
          <b>Filter</b>
        </v-col>
        <v-col cols="3">
          <gw-autocomplete
            v-model="month"
            :items="months"
            label="Lanuch"
            item-text="name"
            item-value="name"
            outlined
            dense
            hide-details
            small-chips
            clearable
            deletable-chips
            :return-object="false"
          />
        </v-col>
        <v-col cols="3">
          <gw-autocomplete
            v-model="designer"
            :items="designers"
            label="Designer"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            small-chips
            clearable
            deletable-chips
            :return-object="false"
          />
        </v-col>
        <v-col cols="3">
          <gw-autocomplete
            v-model="merchandiser"
            :items="merchandisers"
            label="Merchandiser"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            small-chips
            clearable
            deletable-chips
            :return-object="false"
          />
        </v-col>
      </v-row>
    </div>
    <div class="tableContainer">
      <table>
        <thead>
          <tr>
            <th>Collection</th>
            <!-- <th>Launch Date</th> -->
            <th>Designer</th>
            <th>Merchandiser</th>
            <th>Factory</th>
            <th>Timeline</th>
            <th
              v-for="step in steps"
              :key="step">
              {{ step }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(collection, collectionName, collectionIndex) in filteredData">
            <template
              v-for="(factory, factoryName, factoryIndex) in collection.factorys"
            >
              <template
                v-for="(timeline, timelineName, timelineIndex) in factory.timelines"
              >
                <tr
                  :key="collectionIndex + '-' + factoryIndex + '-' + timelineIndex"
                  :class="{'border-top': factoryIndex === 0 && timelineIndex === 0}">
                  <td
                    v-if="factoryIndex === 0 && timelineIndex === 0"
                    class="sticky-col"
                    :rowspan="getRowSpan(collection)">
                    <div :class="[collection.status]">
                      <b>{{ collectionName }}</b>
                      <br>
                      <span>{{ formatDate(collections[collectionName]['Launch Date']) }}</span>
                    </div>
                  </td>
                  <td
                    v-if="factoryIndex === 0 && timelineIndex === 0"
                    :rowspan="getRowSpan(collection)">
                    <div :class="[collection.status]">
                      <div
                        v-for="design in collections[collectionName]['Designer']"
                        :key="design.name">
                        <b>{{ design.name }}</b>
                      </div>
                      <button
                        style="margin-top: 10px;"
                        @click="copyContent(`https://storage.googleapis.com/gw_production_plan/ics/${collection.collectionRecordId}-d.ics`)">
                        📅 iCal
                      </button>
                    </div>
                  </td>
                  <td
                    v-if="factoryIndex === 0 && timelineIndex === 0"
                    :rowspan="getRowSpan(collection)">
                    <div :class="[collection.status]">
                      <div
                        v-for="mer in collections[collectionName]['Merchandiser']"
                        :key="mer.name">
                        <b>{{ mer.name }}</b>
                      </div>
                      <button
                        style="margin-top: 10px;"
                        @click="copyContent(`https://storage.googleapis.com/gw_production_plan/ics/${collection.collectionRecordId}-m.ics`)">
                        📅 iCal
                      </button>
                    </div>
                  </td>
                  <!--  -->
                  <td
                    v-if="timelineIndex === 0"
                    :rowspan="getFactoryRowSpan(factory)">
                    <div :class="[factory.status]">
                      <b> {{ factoryName }}</b>
                    </div>
                  </td>
                  <td>
                    <div :class="[timeline.status]">
                      <b>{{ timelineName }}</b>
                    </div>
                  </td>
                  <!--  -->
                  <td
                    v-for="step in steps"
                    :key="step"
                  >
                    <div
                      v-if="factoryIndex === 0 && timelineIndex === 0"
                      style="margin-bottom: 4px;">
                      <b>{{ ((collection.steps[step].done / collection.steps[step].total) * 100).toFixed(0) }}%
                        ({{ collection.steps[step].done }}/{{ collection.steps[step].total }})</b>
                    </div>
                    <div
                      :class="[timeline.steps[step].status]"
                    >
                      <!--  -->
                      <span>
                        {{ formatDate(timeline.steps[step].dueDate) }}
                        <br>
                        {{ timeline.steps[step].doneRate }}%
                        ({{ timeline.steps[step].doneRatio }})
                      </span>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
html {
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.body {
  flex-direction:column;
  display:flex;
  height:calc(100vh - 70px) !important;
  padding-top: 10px;
}

.filter-bar {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
}

.filter-bar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
}

.tableContainer {
  overflow:scroll;
  flex-grow:1;
  width:100%;
}
table, th, td {
  /* border: 1px solid rgb(235, 235, 243); */
  background-color: #fff;
}

table {
  height: 1px;
  border-collapse: collapse;
}

thead tr > th {
  position: sticky;
  padding: 8px;
  text-wrap: nowrap;
  z-index:2;
  top: 0;
}

thead tr > :first-child {
  z-index:3;
  left:0;
  top:0;
}

td {
  text-align:center;
  font-size: 13px;
  color: #3c3e40;
  background-color: #fff;
  height: inherit;
  padding: 8px;
  text-wrap: nowrap;
}
tbody tr:hover td {
  background-color: rgb(244, 244, 255);
}

.sticky-col {
  position: sticky;
  left:0;
  z-index:1;
}

.border-top {
  border-top: 18px solid rgb(235, 235, 243);
}
.done {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(207, 243, 228);
    background-color: rgb(233 247 241);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
    .upcoming {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(214, 226, 251);
    background-color: rgb(230 239 254);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
  .soon {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(252 250 190);
    background-color:rgb(255 254 231);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
  .late {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(234 201 195);
    background-color: rgb(249 236 232);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
  .toolate {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(255, 195, 195);
    background-color: rgb(255, 60, 60);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
  .verytoolate {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(65 30 114);
    background-color: rgb(83 49 130);
    color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }

  button:hover {
    text-decoration: underline;
  }

/* tbody tr > :first-child {
  background:#ddd;
  position: sticky;
  z-index:1;
  left:0;
} */
</style>

<script>
  export default {
    data () {
      return {
        steps: [],
        collections: {},
        data: {},
        designers: [],
        designer: null,
        merchandisers: [],
        merchandiser: null,
        months: [
          'January', 'February', 'March', 'April',
          'May', 'June', 'July', 'August',
          'September', 'October', 'November', 'December'
        ],
        month: null
      }
    },
    computed: {
      filteredData () {
        return Object.fromEntries(
          Object.entries(this.data).filter(([key]) => {
            const matchMonth = this.month ? this.getFullMonth(this.collections[key]['Launch Date']) === this.month : true
            const matchDesigner = this.designer
              ? (this.collections[key]['Designer'] || []).some((obj) => obj.id === this.designer) : true
            const matchMerchandiser = this.merchandiser
              ? (this.collections[key]['Merchandiser'] || []).some((obj) => obj.id === this.merchandiser) : true
            return matchMonth && matchDesigner && matchMerchandiser
          })
        )
      }
    },
    async mounted () {
      try {
        const stepsResponse = await fetch(`https://storage.googleapis.com/gw_production_plan/steps.json?t=${Date.now()}`)
        const stepsJsonData = await stepsResponse.json()
        this.steps = stepsJsonData.sort()

        // ====================

        const collectionResponse = await fetch(`https://storage.googleapis.com/gw_production_plan/collection.json?t=${Date.now()}`)
        const collectionJsonData = await collectionResponse.json()
        this.collections = collectionJsonData

        // ====================

        const response = await fetch(`https://storage.googleapis.com/gw_production_plan/fusion.json?t=${Date.now()}`)
        const jsonData = await response.json()
        this.data = jsonData

        // ====================

        Object.entries(this.collections).forEach(([, v]) => {
          if (v.Designer) this.designers.push(...v.Designer)
        })

        // ====================

        Object.entries(this.collections).forEach(([, v]) => {
          if (v.Merchandiser) this.merchandisers.push(...v.Merchandiser)
        })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    methods: {
      getFullMonth (d) {
        const date = new Date(d)
        return date.toLocaleString('en-GB', { month: 'long', timeZone: 'Asia/Bangkok' })
      },
      formatDate (d) {
        const date = new Date(d)
        return date.toLocaleDateString('en-GB',
          { weekday: 'short', day: '2-digit', month: 'long', year: 'numeric', timeZone: 'Asia/Bangkok' })
      },
      getRowSpan (collection) {
        // Calculate rowspan for the Collection column based on the number of timelines in all factories within a collection
        return Object.values(collection.factorys)
          .reduce((sum, factory) => sum + Object.keys(factory.timelines).length, 0)
      },
      getFactoryRowSpan (factory) {
        // Calculate rowspan for the Factory column based on the number of timelines within the factory
        return Object.keys(factory.timelines).length
      },
      async copyContent (text) {
        try {
          await navigator.clipboard.writeText(text)
          /* Resolved - text copied to clipboard successfully */
        } catch (err) {
          console.error('Failed to copy: ', err)
          /* Rejected - text failed to copy to the clipboard */
        }
      }
    }
  }
  </script>

import ReleaseNoteProvider from '@/resources/ReleaseNoteProvider'

const ReleaseNoteService = new ReleaseNoteProvider()

const state = {
  unacceptedCount: 0
}

const mutations = {
  SET_UNACCEPTED_COUNT (state, count) {
    state.unacceptedCount = count
  },
  DECREMENT_UNACCEPTED_COUNT (state) {
    if (state.unacceptedCount > 0) {
      state.unacceptedCount -= 1
    }
  }
}

const actions = {
  async fetchUnacceptedCount ({ commit }) {
    try {
      const { data } = await ReleaseNoteService.getUnacceptedReleaseNotesCount()
      commit('SET_UNACCEPTED_COUNT', data)
    } catch (error) {
      console.error('Error fetching unaccepted count:', error)
    }
  },
  async acknowledgeReleaseNote ({ commit }, id) {
    try {
      await ReleaseNoteService.acknowledgeReleaseNote({ releaseNoteId: id })
      commit('DECREMENT_UNACCEPTED_COUNT')
    } catch (error) {
      console.error('Error acknowledging release note:', error)
      throw error
    }
  }
}

const getters = {
  unacceptedCount: (state) => state.unacceptedCount
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ReleaseNoteProvider extends HttpRequest {
  getReleaseNote (query) {
    this.setHeader(getAuthToken())
    return this.get('/release-notes', query)
  }

  getReleaseNoteByUser (query) {
    this.setHeader(getAuthToken())
    return this.get('/release-notes/list/user', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/release-notes/${id}`)
  }

  getUnacceptedReleaseNotesCount () {
    this.setHeader(getAuthToken())
    return this.get('/release-note-accepts/count')
  }

  getReleaseNoteByPaginate (paginate) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/release-notes', paginate)
  }

  deleteReleaseNote (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/release-notes?id=${id}`)
  }

  acknowledgeReleaseNote (payload) {
    this.setHeader(getAuthToken())
    return this.post('/release-note-accepts', payload)
  }

  createReleaseNote (payload) {
    this.setHeader(getAuthToken())
    return this.post('/release-notes', payload)
  }

  updateReleaseNote (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/release-notes/${id}`, payload)
  }

  updatePriority (payloads) {
    this.setHeader(getAuthToken())
    return this.patch('/release-notes/priority', payloads)
  }

  updateStatus (payloads) {
    this.setHeader(getAuthToken())
    return this.patch('/release-notes/status', payloads)
  }
}

export default ReleaseNoteProvider

import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class IBoxWarehouseSettingProvider extends HttpRequest {
  getIBoxWarehouseSetting () {
    this.setHeader(getAuthToken())
    return this.get('/i-box-warehouse-setting/list')
  }

  createIBoxWarehouseSetting (payload) {
    this.setHeader(getAuthToken())
    return this.post('/i-box-warehouse-setting/create-warehouse', payload)
  }

  updateIBoxWarehouseSetting (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/i-box-warehouse-setting/${id}`, payload)
  }

  deleteIBoxWarehouseSetting (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/i-box-warehouse-setting/${id}`)
  }
}

export default IBoxWarehouseSettingProvider

<template>
  <v-container fluid>
    <div class="toolbar d-flex flex-row justify-space-between align-center elevation-3 mb-3">
      <span>
        DHL Express Setting
      </span>
      <div
        class="d-flex flex-row align-center"
        style="gap: 0.625rem;">
        <v-btn
          color="error"
          outlined
          rounded
          small
          :loading="loading"
          @click="onReset()">
          {{ $t('button.Reset') }}
        </v-btn>
        <v-btn
          color="success"
          rounded
          small
          :loading="loading"
          @click="onSubmit()">
          {{ $t('button.Save') }}
        </v-btn>
      </div>
    </div>
    <validation-observer
      ref="form"
      @submit.prevent="onSubmit()">
      <v-row class="px-8">
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              class="card-title">
              Key & Measurement
            </v-col>
            <v-col
              cols="12"
              md="6">
              <gw-text-field
                v-model.trim="formData.key"
                type="password"
                label="DHL Key"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <gw-text-field
                v-model.trim="formData.unitOfMeasurement"
                label="Unit of Measurement"
                :rules="rules.required"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              class="card-title">
              Shipper Location
            </v-col>
            <v-col
              cols="12"
              md="4">
              <gw-text-field
                v-model.trim="formData.shipperDetails.postalCode"
                label="Postal Code"
                :rules="rules.required"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
            <v-col
              cols="12"
              md="4">
              <gw-text-field
                v-model.trim="formData.shipperDetails.cityName"
                label="City Name"
                :rules="rules.required"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
            <v-col
              cols="12"
              md="4">
              <gw-text-field
                v-model.trim="formData.shipperDetails.countryCode"
                label="Country Code"
                :rules="rules.required"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              class="card-title">
              Contact Information
            </v-col>
            <v-col
              cols="12"
              md="6">
              <gw-text-field
                v-model.trim="formData.contactInformation.companyName"
                label="Company Name"
                :rules="rules.required"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <gw-text-field
                v-model.trim="formData.contactInformation.email"
                label="Email"
                :rules="rules.required"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model.trim="formData.contactInformation.fullName"
                label="Full Name"
                :rules="rules.required"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <gw-text-field
                v-model.trim="formData.contactInformation.mobilePhone"
                label="Mobile Phone"
                :rules="rules.required"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <gw-text-field
                v-model.trim="formData.contactInformation.phone"
                label="Phone"
                :rules="rules.required"
                color="secondary"
                outlined
                dense
                hide-details
                :loading="loading" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="card-title">
              Account settings
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex justify-end align-center">
              <v-btn
                color="success"
                plain
                :loading="loading"
                @click="addAccount()">
                <v-icon>mdi-plus</v-icon>
                Add
              </v-btn>
            </v-col>
            <template v-for="(account, index) in formData.accounts">
              <v-col
                :key="`account-type-code-${index}`"
                cols="12"
                md="6">
                <gw-autocomplete
                  v-model="formData.accounts[index].typeCode"
                  :items="accountTypeCodes"
                  item-text="text"
                  item-value="value"
                  :rules="rules.required"
                  label="Type Code"
                  color="secondary"
                  outlined
                  dense
                  hide-details
                  :loading="loading" />
              </v-col>
              <v-col
                :key="`account-number-${index}`"
                cols="12"
                md="6">
                <gw-text-field
                  v-model.trim="formData.accounts[index].number"
                  label="Number"
                  :rules="rules.required"
                  color="secondary"
                  outlined
                  dense
                  hide-details
                  :loading="loading">
                  <template #append>
                    <v-icon
                      color="error"
                      @click="removeAccount(index)">
                      mdi-delete
                    </v-icon>
                  </template>
                </gw-text-field>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="card-title">
              Registration Numbers
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex justify-end align-center">
              <v-btn
                color="success"
                plain
                :loading="loading"
                @click="addRegistrationNumbers()">
                <v-icon>mdi-plus</v-icon>
                Add
              </v-btn>
            </v-col>
            <template v-for="(regis, index) in formData.registrationNumbers">
              <v-col
                :key="`regis-issuer-${index}`"
                cols="12"
                md="4">
                <gw-text-field
                  v-model.trim="formData.registrationNumbers[index].issuerCountryCode"
                  label="Issuer Country Code"
                  :rules="rules.required"
                  color="secondary"
                  outlined
                  dense
                  hide-details
                  :loading="loading" />
              </v-col>
              <v-col
                :key="`regis-type-code-${index}`"
                cols="12"
                md="4">
                <gw-autocomplete
                  v-model="formData.registrationNumbers[index].typeCode"
                  :items="registrationTypeCodes"
                  item-text="text"
                  item-value="value"
                  :rules="rules.required"
                  label="Type Code"
                  color="secondary"
                  outlined
                  dense
                  hide-details
                  :loading="loading" />
              </v-col>
              <v-col
                :key="`regis-number-${index}`"
                cols="12"
                md="4">
                <gw-text-field
                  v-model.trim="formData.registrationNumbers[index].number"
                  label="Number"
                  :rules="rules.required"
                  color="secondary"
                  outlined
                  dense
                  hide-details
                  :loading="loading">
                  <template #append>
                    <v-icon
                      color="error"
                      @click="removeRegistrationNumbers(index)">
                      mdi-delete
                    </v-icon>
                  </template>
                </gw-text-field>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="card-container elevation-3">
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="card-title">
              Pick-up Warehouses
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex justify-end align-center">
              <v-btn
                color="success"
                plain
                :loading="loading"
                @click="showPickUpWarehouseModal()">
                <v-icon>mdi-plus</v-icon>
                Add
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="pickUpWarehouseHeaders"
                :items="formData.pickUpWarehouses"
                :items-per-page="-1"
                :loading="loading"
                class="elevation-1"
                fixed-header
                hide-default-footer>
                <template #[`item.actions`]="{ item, index }">
                  <div class="d-flex align-center">
                    <v-icon
                      small
                      class="mr-2"
                      @click="showPickUpWarehouseModal(item)">
                      mdi-pencil
                    </v-icon>
                    <gw-icon-hover
                      small
                      class="sku-table-bin"
                      icon-name="mdi-delete"
                      icon-hover="mdi-delete-empty"
                      @click="removePickUpWarehouse(index)" />
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </validation-observer>
    <PickUpWarehouseModal
      v-model="active"
      :data="selectedItem"
      :selected-warehouses="selectedWarehouses"
      @submit="addPickUpWarehouse($event)" />
  </v-container>
</template>

<script>
import DHLExpressSettingsProvider from '@/resources/DHLExpressSettingsProvider'
import PickUpWarehouseModal from '../components/PickUpWarehouseModal.vue'

const DHLExpressSettingsService = new DHLExpressSettingsProvider()

export default {
  components: {
    PickUpWarehouseModal
  },
  data () {
    return {
      loading: true,
      active: false,
      formData: {
        key: '',
        contactInformation: {
          email: '',
          phone: '',
          mobilePhone: '',
          companyName: '',
          fullName: ''
        },
        registrationNumbers: [
          {
            typeCode: 'VAT',
            number: '',
            issuerCountryCode: 'TH'
          }
        ],
        shipperDetails: {
          postalCode: '',
          cityName: '',
          countryCode: ''
        },
        unitOfMeasurement: 'metric',
        accounts: [
          {
            typeCode: 'shipper',
            number: ''
          }
        ],
        pickUpWarehouses: []
      },
      rules: {
        required: {
          required: true
        },
        number: [
          (v) => !/^\d+&/.test(v) || 'This field must be number'
        ]
      },
      accountTypeCodes: [
        {
          text: 'Shipper',
          value: 'shipper'
        },
        {
          text: 'Payer',
          value: 'payer'
        }
      ],
      registrationTypeCodes: [
        {
          text: 'VAT',
          value: 'VAT'
        }
      ],
      pickUpWarehouseHeaders: [
        {
          text: 'Name',
          value: 'warehouse.name',
          align: 'center',
          sortable: false
        },
        {
          text: 'Code',
          value: 'warehouse.code',
          align: 'center',
          sortable: false
        },
        {
          text: 'Pick-up Mode',
          value: 'mode',
          align: 'center',
          cellClass: 'text-capitalize',
          sortable: false
        },
        {
          text: 'Pick-up Time',
          value: 'pickUpTime',
          align: 'center',
          sortable: false
        },
        {
          text: 'Close Time',
          value: 'closeTime',
          align: 'center',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],
      selectedItem: null
    }
  },
  computed: {
    selectedWarehouses () {
      return this.formData.pickUpWarehouses.map((item) => item.warehouse)
    }
  },
  mounted () {
    this.getStoreSetting()
  },
  methods: {
    addAccount () {
      this.formData.accounts.push({
        typeCode: 'shipper',
        number: ''
      })
    },
    removeAccount (index) {
      this.formData.accounts.splice(index, 1)
    },
    addRegistrationNumbers () {
      this.formData.registrationNumbers.push({
        typeCode: 'VAT',
        number: '',
        issuerCountryCode: 'TH'
      })
    },
    removeRegistrationNumbers (index) {
      this.formData.registrationNumbers.splice(index, 1)
    },
    showPickUpWarehouseModal (item = null) {
      this.selectedItem = item
      this.active = true
    },
    addPickUpWarehouse (data) {
      if (!data) {
        return
      }

      let index = this.formData.pickUpWarehouses.findIndex((item) => data.warehouse.id === item.warehouse.id)

      if (this.selectedItem) {
        index = this.formData.pickUpWarehouses.findIndex((item) => this.selectedItem.warehouse.id === item.warehouse.id)
      }

      if (index === -1) {
        this.formData.pickUpWarehouses.push(data)
      } else {
        this.formData.pickUpWarehouses[index] = data
      }
    },
    removePickUpWarehouse (index) {
      this.formData.pickUpWarehouses.splice(index, 1)
    },
    async getStoreSetting () {
      try {
        this.loading = true

        const { data } = await DHLExpressSettingsService.getLastSetting()

        this.formData = {
          ...this.formData,
          ...(data || {})
        }
      } catch (error) {
        console.error('getStoreSetting', error)
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loading = false
      }
    },
    onReset () {
      this.formData = {
        key: '',
        contactInformation: {
          email: '',
          phone: '',
          mobilePhone: '',
          companyName: '',
          fullName: ''
        },
        registrationNumbers: [
          {
            typeCode: 'VAT',
            number: '',
            issuerCountryCode: 'TH'
          }
        ],
        shipperDetails: {
          postalCode: '',
          cityName: '',
          countryCode: ''
        },
        unitOfMeasurement: 'metric',
        accounts: [
          {
            typeCode: 'shipper',
            number: ''
          }
        ],
        pickUpWarehouses: []
      }
      this.getStoreSetting()
    },
    async onSubmit () {
      try {
        this.loading = true

        const isValid = await this.$refs.form.validate()

        if (isValid) {
          await DHLExpressSettingsService.updateSetting(this.formData)
          this.onReset()
          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: 'Update Success',
            type: 'success'
          })
        }
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.toolbar {
  position: sticky;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  padding: 1.25rem 1.875rem;
  border-radius: 0.625rem;
}
.toolbar span {
  font-weight: bold;
  font-size: 1.25rem;
}
.card-container {
  padding: 1.25rem 1.875rem;
  background-color: #fff;
  border-radius: 0.625rem;
  margin-top: 1.25rem;
}
.card-title {
  font-weight: bold;
  font-size: 1.35rem;
}
</style>

<template>
  <v-container
    class="rounded elevation-3 bg-white h-full"
    fluid>
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-row align-center">
        <span class="header">Pick Pack Report</span>
        <v-btn
          class="ml-1"
          color="success"
          icon
          :loading="exporting"
          @click="exportCsv">
          <v-icon>
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      no-gutters>
      <v-col cols="12">
        <pick-pack-report-header
          v-model="filter"
          :warehouses="warehouses"
          @on-find="find()" />
      </v-col>
      <v-col cols="12">
        <div class="w-full d-flex justify-end align-center">
          <v-btn
            class="mr-3"
            color="secondary"
            :disabled="!totalReports"
            @click="exportDataToCSV()">
            Export ({{ totalReports }})
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="reports"
          hide-default-footer
          no-data-text="ไม่มีข้อมูลในช่วงเวลานี้"
          loading-text="กรุณารอสักครู่...">
          <template #[`item.createdAt`]="{ item }">
            <span>
              {{ getDateFormat(item.createdAt) }}
            </span>
          </template>
          <template #[`item.orderId`]="{ item }">
            <span>
              {{ item.order.orderId }}
            </span>
          </template>
          <template #[`item.pickpackState`]="{ item }">
            <span>
              {{ checkState(item.pickpackState) }}
            </span>
          </template>
          <template #[`item.countSku`]="{ item }">
            <span>
              {{ item.countSku | showNumberFormat() }}
            </span>
          </template>
          <template #[`item.createdBy`]="{ item }">
            <span>
              {{ item.createdBy.email }}
            </span>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="totalPages"
          circle
          total-visible="7" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import PickPackProvider from '@/resources/PickPackProvider'
import PerformanceReviewProvider from '@/resources/PerformanceReviewProvider'
import PickPackReportHeader from '../components/PickPackReportHeader.vue'

const PickPackService = new PickPackProvider()
const PerformanceReviewService = new PerformanceReviewProvider()

export default {
  components: { PickPackReportHeader },
  data () {
    return {
      page: 1,
      perPage: 20,
      totalPages: 0,
      totalReports: 0,
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
          sortable: true,
          align: 'center'
        },
        {
          text: 'Order ID',
          value: 'orderId',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Pick Pack State',
          value: 'pickpackState',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Item Amount',
          value: 'countSku',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Responsible',
          value: 'createdBy',
          sortable: false,
          align: 'center'
        }
      ],
      reports: [],
      filter: {
        dates: [
          this.$dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
          this.$dayjs().format('YYYY-MM-DD')
        ],
        warehouse: null,
        pickPackState: null,
        searchInput: ''
      },
      exporting: false
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
    }
  },
  watch: {
    page () {
      this.fetchReports()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async fetchReports (isClear = false) {
      try {
        this.setLoading({ active: true })

        if (isClear) {
          this.page = 1
        }

        const query = {
          page: this.page,
          limit: this.perPage,
          startDate: this.$dayjs(this.filter.dates[0]).add(7, 'h').utc().toISOString(),
          endDate: this.$dayjs(this.filter.dates[1]).add(31, 'h').utc().toISOString(),
          warehouseId: this.filter.warehouse.id,
          search: this.filter.searchInput
        }

        if (this.filter.pickpackState && this.filter.pickpackState !== 'All') {
          query.pickpackState = this.filter.pickpackState.toLowerCase()
        }

        const { data } = await PickPackService.getPickPackLogs(query)

        this.totalPages = data.pages
        this.totalReports = data.total
        this.reports = data.results
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    getDateFormat (date) {
      return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    checkState (state) {
      switch (state) {
        case 'scanning': return 'Scanning Product'
        case 'validated': return 'Validated Product'
        case 'completed': return 'Completed Order'
        default: return 'Unknown'
      }
    },
    find () {
      this.fetchReports(true)
    },
    async exportDataToCSV () {
      try {
        this.setLoading({ active: true })

        const limit = 3000
        let rounds = Math.ceil(this.totalReports / limit)
        rounds = new Array(rounds).fill(0).map((item, index) => index)

        for await (const round of rounds) {
          const query = {
            page: round + 1,
            limit,
            startDate: this.$dayjs(this.filter.dates[0]).add(7, 'h').utc().toISOString(),
            endDate: this.$dayjs(this.filter.dates[1]).add(31, 'h').utc().toISOString(),
            warehouseId: this.filter.warehouse.id,
            search: this.filter.searchInput
          }

          if (this.filter.pickpackState && this.filter.pickpackState !== 'All') {
            query.pickpackState = this.filter.pickpackState.toLowerCase()
          }

          const { data } = await PickPackService.getPickPackLogs(query)
          const items = data.results.map((report) => ({
            date: this.getDateFormat(report.createdAt),
            orderId: report.order.orderId,
            pickpackState: report.pickpackState,
            productAmount: report.countSku,
            responsible: report.createdBy.email,
            userId: report.createdBy.id
          }))

          const options = {
            filename: `PickPack-Report-${round + 1}`,
            showLabels: true,
            useKeysAsHeaders: true
          }

          const csvExporter = new ExportToCsv(options)
          csvExporter.generateCsv(items)
        }
      } catch (error) {
        console.error('fetchProductsToExport', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async exportCsv () {
      try {
        this.exporting = true

        const { data: performanceReport } = await PerformanceReviewService.getPickPackReport({
          startDate: `${this.filter.dates[0]}T00:00:00.000+07:00`,
          endDate: `${this.filter.dates[1]}T23:59:59.999+07:00`
          // warehouseId: this.filter?.warehouse?.id || null
        })

        const mappedReport = performanceReport.map((report) => ({
              id: report.userId,
              email: report.email,
              itemTotal: Number(report.countSku).toLocaleString(),
              net: Number(report.totalNet).toLocaleString()
            }))
            .sort((a, b) => Number(a.id) - Number(b.id))

        const options = {
          filename: `Pick_Pack_Report_${this.filter.dates[0]}_${this.filter.dates[1]}`,
          showLabels: true,
          useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(mappedReport)
      } catch (error) {
        console.error('exportCsv', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>

<style scoped>
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.header {
  font-size: 1.5rem;
  font-weight: bold;
}
.bg-white {
  background-color: white !important;
}
</style>

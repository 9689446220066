<template>
  <section class="celebs-management">
    <v-card class="celebs-container">
      <v-row>
        <v-col
          cols="12"
          class="pl-10">
          <p class="text-h4 font-text-bold">
            CELEBS
          </p>
        </v-col>
      </v-row>
      <v-row
        class="px-5"
        align="center"
      >
        <v-col
          lg="6"
          md="12"
          cols="12"
        >
          <v-text-field
            v-model="tempSearch"
            placeholder="ชื่อ, IG หรือ Facebook"
            outlined
            dense
            hide-details
            append-icon="mdi-magnify"
            @click:append="searchHandler()" />
        </v-col>
        <v-col
          lg="2"
          sm="4"
          cols="12"
        >
          <v-btn
            block
            color="success"
            style="font-size: 12px;"
            @click="createModalToggle(true)">
            Add new celeb
          </v-btn>
        </v-col>
        <v-col
          lg="2"
          sm="4"
          cols="12"
        >
          <v-btn
            block
            color="primary"
            style="font-size: 12px;"
            @click="importDataToCSV()">
            <v-icon>mdi-file-import</v-icon>
            Import CSV
          </v-btn>
        </v-col>
        <v-col
          lg="2"
          sm="4"
          cols="12"
        >
          <v-btn
            :loading="
              loading"
            block
            color="secondary"
            style="font-size: 12px;"
            @click="exportDataToCSV()">
            <v-icon>mdi-file-export</v-icon>
            Export CSV
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <celeb-table
          v-model="query"
          :items="celebs"
          :loading="loading"
          @click:remove="removeCelebById($event)"
          @click:edit="editCeleb($event)" />
      </v-card-text>
    </v-card>
    <create-celeb-modal
      v-model="createModalActive"
      :edit-data="editData"
      @create:celeb="fetchCelebs()"
      @clear:edit="editData = null"
    />

    <import-modal-preview
      v-model="importModalActive"
      @imported="fetchCelebs()"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import CelebProvider from '@/resources/CelebProvider'
import CelebTable from '../components/CelebTable.vue'
import CreateCelebModal from '../components/CreateCelebModal.vue'
import ImportModalPreview from '../components/ImportModalPreview.vue'

const CelebService = new CelebProvider()

export default {
  components: {
    CelebTable,
    CreateCelebModal,
    ImportModalPreview
  },
  data () {
    return {
      searchDelay: null,
      tempSearch: null,
      createModalActive: false,
      importModalActive: false,
      celebs: [],
      loading: false,
      query: {
        search: null,
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdAt'],
        sortDesc: [true]
      },
      editData: null
    }
  },
  watch: {
    query: {
      handler () {
        this.fetchCelebs()
      },
      deep: true
    }
  },
  mounted () {
    this.fetchCelebs()
  },
  methods: {
    ...mapActions({
      setModal: 'Components/setModal',
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    searchHandler () {
      this.query.search = this.tempSearch
    },
    async removeCelebById (id) {
      try {
        this.loading = true
        this.setModal({
          value: true,
          title: 'Remove celeb',
          message: 'Do you want to remove celeb?',
          confirmText: 'Confirm',
          confirmType: 'success',
          cancelType: '',
          cancelText: 'Cancel',
          onConfirm: async () => {
            const { data } = await CelebService.deleteCeleb(id)

            if (data) {
              this.setSnackbar({
                value: true,
                message: 'Celeb has been deleted',
                type: 'success'
              })

              this.fetchCelebs()
            }
          }
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[error on delete celeb]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    editCeleb (item) {
      this.editData = item
      this.createModalToggle(true)
    },
    createModalToggle (value) {
      this.createModalActive = value
    },
    async fetchCelebs () {
      try {
        this.loading = true
        const { data } = await CelebService.getCelebs(this.query)
        this.celebs = data.results
        this.query.total = data.total
        this.query.pages = data.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[error on create celeb]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async importDataToCSV () {
      this.importModalActive = true
    },
    async exportDataToCSV () {
      try {
        this.loading = true
        const isSearch = this.query.search ? `?search=${this.query.search}` : ''
        const response = await CelebService.exportCelebs(isSearch)

        if (response) {
          const bom = '\uFEFF'
          const blob = new Blob([bom + response], { type: 'text/csv;charset=utf-8;' })
          const url = window.URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url
          const random = new Date().getTime()
          link.setAttribute('download', `Celebs-${random}.csv`)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          this.setSnackbar({
            value: true,
            message: 'Exported successfully!',
            type: 'success'
          })
        } else {
          throw new Error('Failed to fetch the CSV file')
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error exporting CSV: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.celebs-container {
  min-height: calc(100vh - 67px);
}

</style>

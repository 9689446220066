<template>
  <div>
    <v-row>
      <v-col class="pt-0">
        <p class="label mb-0 font-weight-bold">
          Size Unit
        </p>
        <v-select
          v-model="unit"
          :items="units"
          item-text="text"
          item-value="value"
          placeholder="โปรดเลือกหน่วยของ Size chart"
          color="secondary"
          outlined
          dense
          hide-details />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12">
        <h3>
          Sizing Detail
        </h3>
      </v-col>
    </v-row>
    <table class="size-details-table">
      <thead>
        <tr>
          <th>
            <p class="label mb-0 text-center">
              Detail / Size
            </p>
          </th>
          <th
            v-for="(size) in sizeDetails"
            :key="`head-sizeDetails-${size.size}`">
            <p class="label mb-0 text-uppercase text-center">
              {{ size.size }}
            </p>
          </th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(key, keyIndex) in keys"
          :key="`mainkey-${keyIndex}`">
          <td>
            <v-text-field
              v-model="keys[keyIndex]"
              outlined
              dense
              hide-details
              color="secondary"
              @input="insertKey($event, keyIndex)"
              @keydown.enter.native="addMoreSizeDetail()" />
          </td>
          <td
            v-for="(size, sizeIndex) in sizeDetails"
            :key="`sizeDetails-${sizeIndex}-${keyIndex}`">
            <v-text-field
              v-model="size.options[keyIndex].value"
              outlined
              dense
              hide-details
              color="secondary"
              @keydown.enter.native="addMoreSizeDetail()" />
          </td>
          <td>
            <v-btn
              color="error"
              @click="deleteSizeDetail(keyIndex)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>

    <v-btn
      class="mt-4"
      :disabled="disableBtnAddMore"
      small
      @click="addMoreSizeDetail()">
      <v-icon left>
        mdi-plus
      </v-icon>
      add more size
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    sizeUnit: {
      type: String,
      default: 'Inches'
    }
  },
  data () {
    return {
      units: [
        {
          text: 'Milimeter (มิลลิเมตร)',
          value: 'Milimeter'
        },
        {
          text: 'Centimeter (เซนติเมตร)',
          value: 'Centimeter'
        },
        {
          text: 'Inches (นิ้ว)',
          value: 'Inches'
        },
        {
          text: 'Meter (เมตร)',
          value: 'Meter'
        },
        {
          text: 'Milliliter (มิลลิลิตร)',
          value: 'Milliliter'
        }
      ]
    }
  },
  computed: {
    sizeDetails: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    unit: {
      get () {
        return this.sizeUnit
      },
      set (val) {
        this.$emit('update:size-unit', val)
      }
    },
    disableBtnAddMore () {
      return this.sizeDetails.length === 0
    },
    keys () {
      const { sizeDetails } = this
      if (!Array.isArray(sizeDetails) || sizeDetails.length === 0 || !Array.isArray(sizeDetails[0].options) || sizeDetails[0].options.length === 0) {
        return []
      }
      return sizeDetails[0].options.map((option) => option.key)
    }
  },
  methods: {
    addMoreSizeDetail () {
      this.sizeDetails.forEach((sizeDetail) => {
        sizeDetail.options.push({
          key: '',
          value: ''
        })
      })
    },
    insertKey (val, index) {
      this.sizeDetails.forEach((sizeDetail) => {
        sizeDetail.options.splice(index, 1, {
          key: val,
          value: sizeDetail?.options[index]?.value || ''
        })
      })
    },
    deleteSizeDetail (index) {
      this.sizeDetails.forEach((sizeDetail) => {
        sizeDetail.options.splice(index, 1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.size-details-table {
  width: 100%;
}
.field-label {
  font-weight: bold;
}
.text-uppercase {
  text-transform: uppercase;
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="520px"
    transition="dialog-top-transition">
    <v-card>
      <v-toolbar
        dark
        color="primary">
        <v-toolbar-title>Created</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container
        class="products-list-page"
        fluid>
        <v-data-table
          :headers="headers"
          :items="promotionCodes"
          hide-default-footer
          :server-items-length="-1">
          <template v-slot:[`item.amount`]="{ item }">
            {{ discountCompute(item) }}
          </template>
          <template v-slot:[`item.minPrice`]="{ item }">
            {{ showFullPriceFormat(item.minPrice) }}
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            {{ dateCompute(item.dateRange.startDate) }}
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            {{ dateCompute(item.dateRange.endDate) }}
          </template>
        </v-data-table>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="success"
          @click="exportCsv()">
          <v-icon color="green">
            mdi-microsoft-excel
          </v-icon>
          Export Csv
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    promotionCodes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'โค้ด',
          align: 'center',
          sortable: false,
          value: 'code'
        },
        {
          text: 'ส่วนลด',
          align: 'center',
          sortable: false,
          value: 'amount'
        },
        {
          text: 'ยอดซื้อขั้นต่ำ',
          align: 'center',
          sortable: false,
          value: 'minPrice'
        },
        {
          text: 'วันเริ่ม',
          align: 'center',
          sortable: false,
          value: 'startDate'
        },
        {
          text: 'วันสิ้นสุด',
          align: 'center',
          sortable: false,
          value: 'endDate'
        }
      ]
    }
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')
    },
    exportCsv () {
      const data = this.promotionCodes.map((promotion) => ({
          code: promotion.code,
          amount: this.discountCompute(promotion),
          minPrice: this.showFullPriceFormat(promotion.minPrice),
          startDate: this.dateCompute(promotion.dateRange.startDate),
          endDate: this.dateCompute(promotion.dateRange.endDate)
        }))

      const options = {
        filename: 'Promotion Code',
        showLabels: true,
        useKeysAsHeaders: true
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    dateCompute (date) {
      return date ? this.$dayjs(date).format('DD MMM YYYY | HH:mm') : '-'
    },
    discountCompute (promotion) {
      return promotion.discountType === 'amount'
      ? `${this.showFullPriceFormat(promotion.discountNumber)} บาท`
      : `${promotion.discountNumber} %`
    },
    showFullPriceFormat (number) {
      const num = parseFloat(number)
      if (num === 0 || num) {
        return parseFloat(num).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '-'
    }
  }
}
</script>
